import React, { useState } from "react";
import axios from "axios";
import lrz from "lrz";
import { extractErrorMessage } from "../../utils/error.js";
import "./Upload.css";
import { IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";

const Upload = (props) => {
  const [uploadPercent, setUploadPercent] = useState({ current: 0, all: 0 });
  const [photos, setPhotos] = useState([]);
  const [photoCount, setPhotoCount] = useState(0);

  const asyncWait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const userid = props.userid;
  const session = props.session;

  const OSS = "https://oss.umiuni.com/";//TODO: SHOULDN'T IT BE NOT HARDCODED NOW?
  const maxPhotoCount = 9;
  const maxSizeInBytes = 5 * 1024 * 1024;

  const clickUpload = () => {
    const fileInput = document.getElementById("fileupload-button");
    fileInput.click();
  };

  const renderPhotos = () => {
    return (
      <ul className="photos" style={{ cursor: "pointer" }}>
        {photos.map((photo, index) => (
          <li key={index} id={index}>
            <div
              className="photo"
              style={{ backgroundImage: `url(${OSS + photo})` }}
              onClick={() => deletePhoto(index)}
            ></div>
          </li>
        ))}
        <li className="upload" onClick={clickUpload}>
          {photos.length !== 0 ? (
            <IonIcon
              icon={addOutline}
              className="ivu-icon ivu-icon-ios-plus-empty"
            ></IonIcon>
          ) : (
            // <span style={{ fontSize: '28px' }}>{props.$t(`profile.cover`)}</span>
            <span style={{ fontSize: "28px" }}>封面</span>
          )}
        </li>
      </ul>
    );
  };

  const onFileChange = (e) => {
    const files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;

    const numFiles = Math.min(maxPhotoCount - photoCount, files.length);

    const fileList = [];
    for (var i = 0; i < numFiles; i++) {
      const file = files[i];

      if (file.type.indexOf("image") === -1) {
        console.log(`file ${photoCount + 1 + i} is not an image`);
        continue;
      }

      if (file.size > maxSizeInBytes) {
        console.log(
          `file ${
            photoCount + 1 + i
          } is larger than the maximum size allowed (${
            maxSizeInBytes / 1024 / 1024
          } MB)`
        );
        continue;
      }

      fileList.push(file);
    }

    setUploadPercent({
      current: 0,
      all: fileList.length,
    });
    const filePromises = fileList.map((file) => {
      return new Promise((resolve, reject) => {
        lrz(file, { width: 1024 })
          .then(({ file }) => {
            uploadFile(file)
              .then(() => {
                resolve(); // Resolve the promise when uploadFile() is done
              })
              .catch((error) => {
                reject(error); // Reject the promise if there's an error in uploadFile()
              });
          })
          .catch((error) => {
            reject(error); // Reject the promise if there's an error in lrz()
          });
      });
    });

    Promise.all(filePromises)
      .then(() => {
        setUploadPercent({ current: 0, all: 0 });
      })
      .catch((error) => {
        console.log(error);
      });

    if (photoCount + files.length > maxPhotoCount) {
      console.log("You can only upload at most 9 pictures.");
    }
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("userid", userid);
    formData.append("session", session);
    formData.append("file", file);
    return axios
      .post(`${process.env.REACT_APP_API_URL}api/file/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.error) {
          if (res.data.error.includes("Authority Failed")) {
            // props.$Notice.error({
            //   title: 'Error:用户登录Session过期',
            //   desc: '5秒后跳转重新登录界面。请登出后重新登录' + extractErrorMessage(res),
            //   duration: 5000,
            // });
            console.log("Error:用户登录Session过期", res);

            (async () => {
              await asyncWait(5000);
              window.location.href = "/user/logout";
            })();
          } else {
            console.log(res);
          }
        } else {
          setPhotos((prevPhotos) => [...prevPhotos, res.data.key]);
          setPhotoCount((prevPhotoCount) => prevPhotoCount + 1);
        }
      })
      .catch((res) => {
        // props.$Message.error(res.data.error);
        console.log("response error:", res);
      })
      .finally(() => {
        setUploadPercent((prev) => ({
          current: prev.current + 1,
          all: prev.all,
        }));
      });
  };

  const deletePhoto = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
    setPhotoCount(photoCount - 1);
  };

  const clearPhotos = () => {
    setPhotos([]);
    setPhotoCount(0);
  }

  return (
    <div className="img-upload-container">
      <div className="img-upload">
        {uploadPercent.all ? (
          <div>
            <div style={{ textAlign: "center" }}>Uploading...</div>
            <progress
              value={uploadPercent.current}
              max={uploadPercent.all}
              className="progress is-primary"
            ></progress>
          </div>
        ) : (
          renderPhotos()
        )}
        <p style={{ textAlign: "center", padding: "5px" }}>
          {/* {props.$t(`notify.clickToDelete`)} */}
          点击图片删除
        </p>
        <input
          id="fileupload-button"
          type="file"
          // value={""}
          style={{ display: "none" }}
          multiple
          onChange={onFileChange}
        />
        <input name="photo" value={photos.join("|")} style={{ display: "none" }} readOnly />
        <button style={{display: "none"}} id="clearPhotos" onClick={clearPhotos}>ClearContent</button>
      </div>
    </div>
  );
};

export default Upload;
