import React from "react";
import { useNavigate } from "react-router-dom";
import style from './item_card.module.css';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";

//see how to call in Search.js


const ItemCard = ({ item, imageUrl, onClick }) => {
    
    const avatar = "https://oss.umiuni.com/" + item.seller.avatar;

    const navigate = useNavigate();

    const timeAgo = (timestamp) => {

        if(item.type === "merchant_house") {
          return "";
        }

        const timeDiff = new Date() - new Date(timestamp);
        const monthsAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
        const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutesAgo = Math.floor(timeDiff / (1000 * 60));
        if(monthsAgo  > 0) {
          return monthsAgo === 1 ? "1 month ago ·" : `${monthsAgo} months ago ·`;
        } else if (daysAgo > 0) {
          return daysAgo === 1 ? "1 day ago ·" : `${daysAgo} days ago ·`;
        } else if (hoursAgo > 0) {
          return hoursAgo === 1 ? "1 hr ago ·" : `${hoursAgo} hrs ago ·`;
        } else if (minutesAgo > 0) {
          return minutesAgo === 1 ? "1 min ago ·" : `${minutesAgo} mins ago ·`;
        } else {
          return "just now ·";
        }
    }

    const handleUserListings = (event) => {
      event.stopPropagation();
      navigate('/search/used/0');
    }

    const handleChat = (event) => {
      event.stopPropagation();
      navigate(`/chats/${item.seller.userid}`);
    }

    return (
      <div className = {style.itemColumn}>
        <a
          href="#"
          className={style.link}
          onClick = {onClick}
        >
          <div className={style.displayCard}>
            <div className={style.cardImageContainer}>
              <img
                variant="top"
                src = {imageUrl}
                className = {style.cardImage}
              />
              {item.price != 0 && (<div className = {style.tagCover}>
                ${item.price}
              </div>)}
              {item.type === "merchant_house" && item.merchant === 1 &&  (<div className = {style.extraTag}>
                Verified
              </div>)}
            </div>
            <div id = "itemTitle" className={style.cardBody}>
              <br />
              <div className={style.itemName}>
                {item.name}
              </div>
            </div>
            <div>
              <div 
                className = {style.sellerInformation} 
                style = {{
                  paddingTop: "5%",
                  paddingBottom: "3%", 
                  paddingLeft: "2%"  
                }}
                onClick = {handleUserListings}
              >
                <img src = {avatar} className = {style.avatar} />
                &nbsp;
                {item.seller.username}
                <div className = {style.additionalFunction} onClick = {handleChat}>
                  <IoChatbubbleEllipsesOutline />
                </div>
              </div>
              <div className={style.additionalCardInformation}>
                <small>
                  {timeAgo(item.publish_timestamp)}{" "}
                  {item.view} views
                </small>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
};

export default ItemCard;