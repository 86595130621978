import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemCard from "../item_cards/Item_card";
import style from '../../layout.module.css';
import axios from "axios";
import { IoMdMenu } from 'react-icons/io';

const ItemCars = () => {
    let schoolId;
    const storedSchool = JSON.parse(localStorage.getItem("savedSchool"));
  
    if (storedSchool === null) {
      schoolId = 1;
    }
    else {
      schoolId = storedSchool.id;
    }
  
    const [returnDataItems, setReturnDataItems] = useState({});
    const [carCards, setCarCards] = useState(null);
  
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    
    const navigate = useNavigate();

    let itemTypes = ["car"];

    const carOptions = [
        { value: "Electric", label: "Electrics" },
        { value: "Coupe", label: "Coupes" },
        { value: "Sedan", label: "Sedans" },
        { value: "Luxury", label: "Luxuries" },
        { value: "Suv", label: "SUVs" },
        { value: "Pickup", label: "Pickups" },
        { value: "Carother", label: "Other tools" }
    ];
    
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkMobile();
        window.addEventListener("resize", checkMobile);
        return () => {
          window.removeEventListener("resize", checkMobile);
        };
    }, [])
    
    useEffect(() => {
        if (Object.keys(returnDataItems).length === itemTypes.length) {
          createCards();
        }
    }, [returnDataItems]);

    useEffect(() => {
        const fetchDataItems = async () => {
            let items = {};
    
            for (let i = 0; i < itemTypes.length; i++) {
                let urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=12&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
        
            if (itemTypes[i] === "merchant_house") {
                urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=18&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
            }
    
            const config = {
                method: "get",
                url: urlValue,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {},
            };

            try {
                const response = await axios(config);
                items[itemTypes[i]] = response.data.items;
                } catch (error) {
                console.log(error);
                }
            }
          setReturnDataItems(items);
        };
        fetchDataItems();
    }, [schoolId]);

    const detailNavigation = (iid) => {
        return "/detail/" + iid;
    };

    const makeItemCards = (items) => {
        let src = "https://oss.umiuni.com/";
    
        return items.map((currentItem, i) => (
            <ItemCard
                item = {currentItem}
                imageUrl = {src + currentItem.photo}
                onClick = {() =>
                window.location.href = detailNavigation(currentItem.iid)
                }
            />
        ));
    }

    const createCards = () => {
        console.log(returnDataItems);
    
        Object.keys(returnDataItems).forEach((itemType) => {
            const items = returnDataItems[itemType];
            const itemCards = makeItemCards(items);
    
            switch(itemType) {
                case "car":
                setCarCards(itemCards);
                break;
            }
        })
    };

    const redirectSearch = (type) => {
        switch(type) {
            case "Electric":
                navigate("/search/car/电动车/0");
                break;
            case "Coupe":
                navigate("/search/car/coupe/0");
                break;
            case "Sedan":
                navigate("/search/car/轿车/0");
                break;
            case "Luxury":
                navigate("/search/car/超豪华车/0");
                break;
            case "Suv":
                navigate("/search/car/越野车/0");
                break;
            case "Pickup":
                navigate("/search/car/皮卡/0");
                break;
            case "Carother":
                navigate("/search/car/改装配件及工具/0");
                break;
            case "Morecar":
                navigate("/search/car/0");
                break;
        }
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    
    const handleDropdownOptionClick = (optionValue) => {
        setSelectedOption(optionValue);
        setIsOpen(false);
        redirectSearch(optionValue);
    };
    
    const renderDropdownOptions = (options) => {
      return (
        <div className={style.dropdown}>
          <button onClick={toggleDropdown}><IoMdMenu /></button>
          <div className={`${style.dropdownContent} ${isOpen ? style.show : null}`}>
            {options.map(option => (
              <div key={option.value} onClick={() => handleDropdownOptionClick(option.value)}>
                {option.label}
              </div>
            ))}
          </div>
        </div>
      );
    };

    return (
        <Fragment>
            <div className = {style.homepageContainer}>
                <div className = {style.categoryTitle}>
                    <div className = {style.title}>
                        Cars
                    </div>
                    {isMobile ? (
                        <>
                            {renderDropdownOptions(carOptions)}
                        </>
                    ) : (
                        <>
                            <div className={style.hyperLinks} onClick={() => redirectSearch("Electric")}>Electrics</div>
                            <div className={style.hyperLinks} onClick={() => redirectSearch("Coupe")}>Coupes</div>
                            <div className={style.hyperLinks} onClick={() => redirectSearch("Sedan")}>Sedans</div>
                            <div className={style.hyperLinks} onClick={() => redirectSearch("Luxury")}>Luxuries</div>
                            <div className={style.hyperLinks} onClick={() => redirectSearch("Suv")}>SUVs</div>
                            <div className={style.hyperLinks} onClick={() => redirectSearch("Pickup")}>Pickups</div>
                            <div className={style.hyperLinks} onClick={() => redirectSearch("Carother")}>Other tools</div>
                        </>
                        )}
                        <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Morecar")}>
                            More
                        </div>
                </div>
                <div className = {style.cardsContainer}>
                    {carCards}
                </div> 
            </div>  
        </Fragment>
    );
}

export default ItemCars;