import React from "react";
import "./Post.css";
import { useState } from "react";
import {
  Title,
  Description,
  Prices,
  Buttons,
} from "./BasicContent";

const Dining = () => {
    const [formInfo, setFormInfo] = useState({
        name: "",
        price: "",
        original_price: "",
        description: "",
        year: "",
        mileage: "",
      });
    
    return (<div>
        D
    </div>)
}

export default Dining;