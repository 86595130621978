import {NavLink} from 'react-router-dom';
import React from 'react';
import {Fragment} from "react";

function PrivacyPolicy(props) {
  return (
    <Fragment>
      <div>
        <br></br>
        <div>
          <NavLink to="/">Home </NavLink>
          <NavLink to="/Helpabout">About Us</NavLink>
          <NavLink to="/contact-us" style={({ isActive }) => ({
          color: isActive ? 'red' : 'black' })}>
            Contact Us
          </NavLink>
          <NavLink to = "/Newhire">Hire Info</NavLink>
          <NavLink to = "/FAQ">Common Questions</NavLink>
        </div>
        <div className="privacy-policy">
          <h2>Privacy Policy for UmiUni.com</h2>
          <ol>
            <li><strong>Personal Information</strong>: We respect the privacy of our users. We do not collect personal information unless it is necessary (for example, to provide our services or respond to inquiries). When we do collect personal information, we only collect what is reasonably necessary.</li>
            <li><strong>Use of Information</strong>: We use the information we collect to provide our services, respond to users, and improve our platform. We do not share personal information with third parties without the user's consent, except as necessary to provide our services or as required by law.</li>
            <li><strong>Cookies and Other Technologies</strong>: Our platform may use cookies and other technologies to provide and improve our services. Users can control their cookie settings through their browser settings.</li>
            <li><strong>Security</strong>: We take reasonable measures to protect the information we collect from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.</li>
            <li><strong>Changes to This Policy</strong>: We may update this policy from time to time. We will notify users of significant changes by posting a notice on our platform.</li>
            <li><strong>Contact Us</strong>: If you have any questions about this policy or our practices, please contact us.</li>
          </ol>
          <p>This privacy policy is effective as of the date specified. By using our platform, you agree to this privacy policy. If you do not agree with this policy, please do not use our platform. We reserve the right to modify this policy at any time, so please review it frequently. Changes to this policy will be posted on our platform.</p>
        </div>
        <div className="copyright">
          © UmiUni.com, 2016-2040.
        </div>
      </div>
    </Fragment>
  );
}

export default PrivacyPolicy;

