import { Fragment } from "react";
import classes from './Newhire.module.css';

const Newhire = props => {
    return (
      <Fragment>
        <div class="container">
            <h1>Contact Us - Apply to Join the Team</h1>
            <p>Email: admin@umiuni.com</p>
            <p>All Positions WeChat: tiantian20170427</p>
            <p>Please send your resume to admin@umiumi.com, and the alumni team will arrange interviews for suitable candidates.</p>
            <br></br>
            <h2><strong>Introduction</strong></h2>
            <p><u>Umi</u><u>Uni</u><u>.com</u> was founded in 2016 as a comprehensive social platform serving North American students and alumni.</p>
            <h3><strong>Team Members</strong></h3>
            <ul>
              <li>Software engineers currently working at Google, Apple, Facebook, Uber, LinkedIn, Box, etc.</li>
              <li>Alumni from CMU, NYU, UIUC, UCLA, University of Maryland, Stanford, etc.</li>
            </ul>
            
            <h3><strong>Target Users</strong></h3>
            <ul>
              <li>Alumni groups from renowned institutions such as NYU, UIC, CMU, JHU, USC, Purdue, UC-Davis, and others</li>
            </ul>

            <h3><strong>Services Offered</strong></h3>
            <ul>
              <li>Alumni networking events, job opportunities and recruitment</li>
              <li>Local restaurant information, regional discounts</li>
              <li>Second-hand item trading, housing rentals</li>
            </ul>

            <br></br>
            <h2><strong>Recruitment</strong></h2>
            <p>Students or alumni from major U.S. universities:</p>

            <br></br>
            <h3><strong>Software Engineers</strong></h3>
            <h3><blockquote>Minimum requirements:</blockquote></h3>
            <ul>
              <li>Major in computer science, AI, Machine Learning, Software engineering, or related fields</li>
              <li>Full-time or internship experience at U.S. tech companies</li>
            </ul>
            
            <p><blockquote>Preferred requirements:</blockquote></p>
            <ul>
              <li>Python, Java, C++, Node.js</li>
              <li>Git</li>
              <li>React.js, Javascript</li>
              <li>React Native, Android, Objective-C, Swift</li>
            </ul>

            <br></br>
            <h3><strong>Business Operation Associates</strong></h3>
            <h3><blockquote>Minimum requirements:</blockquote></h3>
            <ul>
              <li>Current college student or alumni graduated from a U.S. university</li>
              <li>Passionate about marketing, media, and business operations</li>
              <li>Willing to engage in human resource management</li>
            </ul>

            <p><blockquote>Preferred requirements:</blockquote></p>
            <ul>
              <li>Startup experience</li>
              <li>Full-time operation experience</li>
            </ul>

            <p><blockquote>Duties &amp; Responsibilities:</blockquote></p>
            <ul>
              <li>Assist with market/user research</li>
              <li>Help develop, implement, and operate marketing plans</li>
              <li>Assist in organizing and executing online/offline marketing campaigns</li>
              <li>Participate in business team talent recruitment</li>
            </ul>

            <br></br>
            <p>All Positions WeChat: tiantian20170427</p>
            <p>Please send your resume to admin@umiumi.com, and the alumni team will arrange interviews for suitable candidates.</p>
        </div>
      </Fragment>
    );
};

export default Newhire;

