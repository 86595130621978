import React, { useState, useEffect, useContext, Fragment } from "react";
import style from "./content_sharing.module.css";
import styles from "../../layout.module.css"
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const ContentSharingList = () => {
    const [userId, setUserId] = useState([]);
    const [encodedUserIdMap, setEncodedUserIdMap] = useState({});
    const [name, setName] = useState({});
    const { auth } = useContext(AuthContext);
    const [selfUserId, setSelfUserId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserIds = async () => {
            try {
                const { data } = await axios.get('https://python.umiuni.com:5555/umiuni_content_get_user/?pwd=testing');
                setUserId(data.userid);
                
                const encodePromises = data.userid.map(id => 
                    axios.post("https://umiuni.com:5002/api/user/encode_userid", 
                        { userid: id }, 
                        { headers: { "Content-Type": "application/json" }, maxBodyLength: Infinity }
                    )
                    .then(response => ({ original: id, encoded: response.data.result }))
                    .catch(error => {
                        console.error(`Error encoding userId: ${id}`, error);
                        return null;
                    })
                );

                const encodedData = await Promise.all(encodePromises);
                
                const validEncodings = encodedData.filter(item => item !== null);
                
                const encodedMap = validEncodings.reduce((acc, item) => {
                    acc[item.original] = item.encoded;
                    return acc;
                }, {});

                setEncodedUserIdMap(encodedMap);
                
                // Step 2: Fetch usernames for encoded user IDs
                const userFetchPromises = validEncodings.map(item => 
                    fetch(`${process.env.REACT_APP_API_URL}api/user/get?userid=${item.encoded}`)
                        .then(response => response.json())
                        .then(result => ({ id: item.original, username: result.username }))
                        .catch(error => {
                            console.error(`Error fetching username for encoded userId: ${item.encoded}`, error);
                            return null;
                        })
                );

                const userData = await Promise.all(userFetchPromises);
                
                const validUserData = userData.filter(user => user !== null);
                
                const updatedNames = validUserData.reduce((acc, user) => {
                    acc[user.id] = user.username;
                    return acc;
                }, {});

                setName(updatedNames);
            } catch (error) {
                console.error("Error fetching user IDs or processing data:", error);
            }
        };

        const decryptUserId = async () => {
            try {
                const response = await axios.post(
                    "https://umiuni.com:5002/api/user/decode_userid",
                    { encodedString: auth.userid },
                    { headers: { "Content-Type": "application/json" }, maxBodyLength: Infinity }
                );
                setSelfUserId(response.data.result);
            } catch (error) {
                console.error("Error decrypting user ID:", error);
            }
        };

        fetchUserIds();

        if (auth && auth.userid) {
            decryptUserId();
        }
    }, [auth]);

    return (
        <Fragment>
            <div className = {styles.homepageContainer}>
                <div className={styles.categoryTitle}>
                    <div className={styles.title}>
                        Book Sharing
                    </div>
                    <div className={styles.moreItemsLinks} onClick={() => navigate('./upload')}>
                        Post Book
                    </div>
                </div>
                <div className={style.contentCards} style={{ marginTop: "35px" }}>
                    {selfUserId && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                            <button 
                                className={style.switchSelfContent} 
                                onClick={() => navigate(`/content_sharing/${selfUserId}`)}
                            >
                                My Book <br /> {selfUserId}
                            </button>
                        </div>
                    )}
                <div className={style.switchUser}>
                    {userId
                        .filter(id => id !== selfUserId)
                        .map((id, index) => (
                            <div key={index}>
                                <button 
                                    className={style.switchUserContent} 
                                    onClick={() => navigate(`/content_sharing/${id}`)}
                                >
                                    {name[id] || 'Username'} <br /> {id}
                                </button>
                            </div>  
                        ))}
                        </div>

                </div>
            </div>
        </Fragment>
    );
};

export default ContentSharingList;
