import React, { useEffect, useContext, useState, useRef } from "react";
import axios from "axios";
import style from "./user_manage.module.css"
import ItemCard from "../item_cards/Item_card";
import { AuthContext } from "../context/AuthContext";


const UserManage = () => {

    const { auth } = useContext(AuthContext);
    const listingContainerRef = useRef(null);

    const [userListings, setUserListings] = useState(null);
    const [sellingListings, setSellingListings] = useState(null);
    const [soldListings, setSoldListings] = useState(null);
    const [draftListings, setDraftListings] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [allListings, setAllListings] = useState(null);
    
    const [allListingCards, setAllListingCards] = useState(null);
    const [sellingListingCards, setSellingListingCards] = useState(null);
    const [soldListingCards, setSoldListingCards] = useState(null);
    const [draftListingCards, setDraftListingCards] = useState(null);

    var flag = true;

    useEffect(() => {
        fetchListings();
        fetchUser();
    }, [auth]);

    useEffect(() => {
        if(userListings && currentUser) {
            if(flag) {
                sortData();
                flag = false;
            }
        }
    }, [currentUser, userListings]);
    
    useEffect(() => {
        if(allListings != null) {
            allCards();
            sellingCards();
            soldCards();
            draftCards();
        }
    }, [allListings]);

    const fetchListings = () => {

        const params = {
            userid: auth.userid,
            session: auth.session
        };

        axios.post(`${process.env.REACT_APP_API_URL}api/item/private_list`, params)
        .then((response) => {
            setUserListings(response.data.items);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const fetchUser = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/user/get`, {
            params: {
                "userid": auth.userid
            }
        })
        .then((response) => {
            setCurrentUser(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const sortData = () => {

        const selling = [], sold = [], draft = [], all = [];

        for(let i = 0;i < userListings.length; i++) {
            const tempItem = {...userListings[i]};
            tempItem.seller = {
                avatar: currentUser.avatar,
                username: currentUser.username
            };

            if(tempItem.status === 1) {
                selling.push(tempItem);
            } else if (tempItem.status === 2) {
                sold.push(tempItem);
            } else if (tempItem.status === 0) {
                draft.push(tempItem);
            }
            all.push(tempItem);
        }

        setSellingListings(selling);
        setSoldListings(sold);
        setDraftListings(draft);
        setAllListings(all);
    }

    const detailNavigation = (iid) => {
        return "/detail/" + iid;
    }

    const allCards = () => {

        let content = [];

        for(let i = 0;i < allListings.length; i++) {
            let currentItem = allListings[i];
            if(currentItem.photo === "")  {
                currentItem.photo = "null";
            }
            content.push(
                <ItemCard
                    item = {currentItem}
                    imageUrl = {"https://oss.umiuni.com/" + currentItem.photo}
                    onClick = {() =>
                        window.location.href = detailNavigation(currentItem.iid)
                    }
                />
            );
        }

        setAllListingCards(content);
        
    }

    const sellingCards = () => {
        
        let content = [];

        for(let i = 0;i < sellingListings.length; i++) {
            let currentItem = sellingListings[i];
            if(currentItem.photo === "") {
                currentItem.photo = "null";
            }
            content.push(
                <ItemCard
                    item = {currentItem}
                    imageUrl = {"https://oss.umiuni.com/" + currentItem.photo}
                    onClick = {() =>
                        window.location.href = detailNavigation(currentItem.iid)
                    }
                />
            );
        }

        setSellingListingCards(content);

    }

    const soldCards = () => {
        
        let content = [];

        for(let i = 0;i < soldListings.length; i++) {
            let currentItem = soldListings[i];
            if(currentItem.photo === "") {
                currentItem.photo = "null";
            }
            content.push(
                <ItemCard
                    item = {currentItem}
                    imageUrl = {"https://oss.umiuni.com/" + currentItem.photo}
                    onClick = {() =>
                        window.location.href = detailNavigation(currentItem.iid)
                    }
                />
            );
        }

        setSoldListingCards(content);

    }

    const draftCards = () => {
        let content = [];

        console.log(draftListings);

        for(let i = 0;i < draftListings.length; i++) {
            let currentItem = draftListings[i];
            if(currentItem.photo === "") {
                currentItem.photo = "null";
            }
            content.push(
                <ItemCard
                    item = {currentItem}
                    imageUrl = {"https://oss.umiuni.com/" + currentItem.photo}
                    onClick = {() =>
                        window.location.href = detailNavigation(currentItem.iid)
                    }
                />
            );
        }

        setDraftListingCards(content);

    }


    const handleMenuClick = (index) => {
        setActiveTab(index);
        const listingContainer = listingContainerRef.current;
        const selectedTab = listingContainer.children[index];
        const { left: tabLeft, width: tabWidth } = selectedTab.getBoundingClientRect();
        const { left: containerLeft, width: containerWidth } = listingContainer.getBoundingClientRect();
        listingContainer.scrollTo({
            left: tabLeft - containerLeft + listingContainer.scrollLeft - (containerWidth - tabWidth) / 2,
            behavior: 'smooth'
        });
    }


    return (
        <>
            <div className = {style.userManagePage}>
                <div className = {style.listingSelect}>
                    <div 
                        className = {`${style.listingType} ${activeTab === 0 && style.tabActive}`}
                        onClick={() => handleMenuClick(0)}
                    >All</div>
                    <div 
                        className = {`${style.listingType} ${activeTab === 1 && style.tabActive}`}
                        onClick={() => handleMenuClick(1)}
                    >Selling</div>
                    <div 
                        className = {`${style.listingType} ${activeTab === 2 && style.tabActive}`}
                        onClick={() => handleMenuClick(2)}
                    >Sold</div>
                    <div 
                        className = {`${style.listingType} ${activeTab === 3 && style.tabActive}`}
                        onClick={() => handleMenuClick(3)}
                    >Drafts</div>
                </div>
                <div className = {style.listings} ref = {listingContainerRef}>
                    <div className = {style.tabContent}>
                        <div className = {style.nestContent}>
                            {allListingCards}
                        </div>
                    </div>
                    <div className = {style.tabContent}>
                        <div className = {style.nestContent}>
                            {sellingListingCards}
                        </div>
                    </div>
                    <div className = {style.tabContent}>
                        <div className = {style.nestContent}>
                            {soldListingCards}
                        </div>
                    </div>
                    <div className = {style.tabContent}>
                        <div className = {style.nestContent}>
                            {draftListingCards}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserManage;