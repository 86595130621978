import axios from 'axios';
import { useRef, useState, useEffect, useContext } from 'react';
import { AuthContext } from "../context/AuthContext";
import Header from "../header/Header";
import { useParams } from "react-router-dom";
//import classes from './login.module.css';
import './forum.css'; 
            
const Forum = () => {
  const { schoolId } = useParams();
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [forums, setForums] = useState([]);
  const [userid, setUserId] = useState('');
  const [session, setSession] = useState('');
  const [ftid, setFtid] = useState('');
  const [category, setCategory] = useState(''); // Define category state
  const [subcategory, setSubcategory] = useState(''); // Define subcategory state
  const [title, setHeader] = useState(''); // Define title state
  const [content, setContent] = useState(''); // Define content state  var axios = require('axios');
  //var axios = require('axios');
  const [posts, setPosts] = useState([]); // State for posts
  const [topics, setTopics] = useState([]); // State for topics
  useEffect(() => {
      userRef.current.focus();

  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [user, pwd])

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://umiuni.com/api/forum/list_topic?offset=0&limit=210&sort=time_desc&school_id=1');
          //new code:
          console.log(response.data);
          setForums(response.data);
        } catch (error) {
          console.log(error);
          setErrMsg('Error fetching forums');
        }
       
      };

      fetchData();
  }, [schoolId])

///new code,list all topics:
useEffect(() => {
  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://umiuni.com/api/forum/list_topic?offset=0&limit=210&sort=time_desc&school_id=1');
      console.log('Posts Data:', response.data); // Debugging
      if (response.data && response.data.topics) {
        setForums(response.data.topics);
      } else {
        setErrMsg('No posts data found');
      }
    } catch (error) {
      console.log('Error fetching posts:', error);
      setErrMsg('Error fetching posts');
    }
  };

  fetchPosts();
}, []);

useEffect(() => {
  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://umiuni.com/api/forum/list_topic?offset=0&limit=210&sort=time_desc&school_id=1');
      console.log('Posts Data:', response.data);
      if (response.data && response.data.topics) {
        setForums(response.data.topics);
      } else {
        setErrMsg('No posts data found');
      }
    } catch (error) {
      console.log('Error fetching posts:', error);
      setErrMsg('Error fetching posts');
    }
  };
fetchPosts();
}, []); 

  const handleForumSubmit = async (e) => {
       e.preventDefault();
       var id;
       if (category === "category1") {
	 id = 15;
	}
       else if (category === "category2") {
	 id = 16;
	}
	else {
	 id = 17;
  }

console.log(id, userid, session);
var data = {
      userid: userid,
      session: session,
      title: title,
      forum_id: id
    };
console.log(data);

var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/forum/set_topic`,
      data: data
    };
try {
    const response = await axios(config);
    console.log(JSON.stringify(response.data));
    setFtid(response.data.ftid);

    data = {
      userid: userid,
      session: session,
      ftid: response.data.ftid,
      content: content,
    };
    config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/forum/set_post`,
      data: data,
    };
    const secondResponse = await axios(config);
    console.log(JSON.stringify(secondResponse.data));
    //window.alert("The post has been added successfully!");
    window.alert("Post Added successfully!");
  } catch (error) {
    console.log(error);
  }

}

//////////////////////////////////////////
  const handleSubmit = async (e) => {
      e.preventDefault();
          
      var data = JSON.stringify({
        "username": user,
        "password": pwd,
      });

      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}api/user/login`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };


      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        const userid = response.data.userid;
        const session = response.data.session;
        const school_abbr = response.data.school_abbr;
        setAuth({userid, session, school_abbr});
        setUser('');
        setPwd('');
	      setUserId(userid);
	      setSession(session);
        setSuccess(true);
        })
      .catch(function (error) {
        console.log(error);
        if (!error?.response) {
          setErrMsg('No Server Response');
        } else if (error.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (error.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Login Failed');
        }
        errRef.current.focus();
      });
    }

////////////////////////////////////////

return (
        <>
	    <Header />
            {success || userid ? (
                <section>
                    <br />
                    <p>
                        <a href="#https://umiuni.com">Return Home</a>
                    </p>
		<div className="form-container">
      <form onSubmit={handleForumSubmit}>
        <label>Category</label>
        <select id="category" value={category} onChange={e => setCategory(e.target.value)}>
          <option value="category1">Immigration</option>
          <option value="category2">Job Seeking</option>
          <option value="category3">Credit Card/Sign-up Bonus</option>
          <option value="category4">Girlfriend/Boyfriend/Marriage</option>
          <option value="category5">Individual School</option>
        </select>
        <label htmlFor="subcategory">Subcategory</label>
    <select id="subcategory" value={subcategory} onChange={e => setSubcategory(e.target.value)}>
      <option value="subcategory1">CPT</option>
      <option value="subcategory2">OPT</option>
      <option value="subcategory3">Green Card</option>
    </select>
        <label htmlFor="title">Title</label>
        <input type="text" placeholder="Create An Amazing Topic!" id="title" value={title} onChange={e => setHeader(e.target.value)} />

        <label htmlFor="content">Content</label>
        <textarea id="content" placeholder="Your Chance To Shine!" value={content} onChange={e => setContent(e.target.value)} />

        <button type="submit">Post</button>
      </form>
		</div>
    <hr className="subforum-devider" />
    <div className="container">
        <div className="subforum">
            <div className="subforum-title">

            <h1>All Topics</h1>
            {topics.length > 0 ? (
            topics.map((topic) => (
              <div key={topic.ftid} className="subforum-row">
                <div className="subforum-icon subforum-column center">
                {topic.user && topic.user.avatar ? (
                  <img
                    src={`https://some-avatar-url/${topic.user.avatar}`}
                    alt={topic.user.username}
                    className="avatar"
                  />
                ) : (
                  <i className="fa fa-user-circle"></i> // Default icon if avatar is missing
                )}
              </div>
              <div className="subforum-description subforum-column">
                <h3>Posted by: {topic.user ? topic.user.username : 'Unknown User'}</h3>
                <h4>Topic Title: {topic.title}</h4>
                <p>Views: {topic.view} | Replies: {topic.reply}</p>
              </div>
              <div className="bottom">
                <p className="timestamp">Published on: {new Date(topic.publish_timestamp).toLocaleDateString()}</p>
              </div>
          </div>
          ))
          ) : (
          <p>No forum data available</p>
          )}

                
                  
                <div className="forum-info">
                  <h1>About</h1>
                  <h1>Threads</h1>
                </div>
            </div>
          </div> 
      </div>
    
                </section>
            ) : (
                <div id = "login_content">
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Sign In</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="on"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                            type="password" 
                            id="password"
                            onChange={(e) => setPwd(e.target.value)} 
                            value={pwd}
                            required
                        />
                        <button>Sign In</button>
                    </form>
                    <p className="line">
                        Need an Account?<br />
                        <span className="line">
                            {/*put router link here*/}
                            <a href="/verifyemail">Register</a>
                        </span>
                    </p>
                </div>
            )}
        </>
    )
}

export default Forum;
