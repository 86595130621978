import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from './layout.module.css';
import umiunilogo from './umiunilogo.jpg'
import { MdOutlineBook } from "react-icons/md";
import { MdOutlineHouse } from "react-icons/md";
import { MdOutlineRestaurant } from "react-icons/md";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { MdOutlineDirectionsCar } from "react-icons/md";
import { MdOutlineShoppingBag } from "react-icons/md";

const Layout = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, [])

  const handleButtonClick = (path) => {
    navigate(path);
  };

  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      <div className = {style.homepageContainer}>
        <div className={style.outerContainer}>
          <div className={style.iconGrid}>
            <div className={style.iconContainer} onClick={() => handleButtonClick("/content_sharing")}> 
              <div className={style.iconButton}>
                <MdOutlineBook size={45} />
              </div>
              <span className={style.iconLabel}>Book Sharing</span>
            </div>
            <div className={style.iconContainer} onClick={() => handleExternalLink("https://alumni.umiuni.com/")}> 
              <div className={style.iconButton}>
                <img src={umiunilogo} className={style.umiunilogo}/>
              </div>
              <span className={style.iconLabel}>UmiUni Alumni</span>
              <span className={style.iconLabel}>北美校友圈</span>
            </div>
            <div className={style.iconContainer} onClick={() => handleButtonClick("/cars")}> 
              <div className={style.iconButton}>
                <MdOutlineDirectionsCar size={45} />
              </div>
              <span className={style.iconLabel}>Cars</span>
            </div>
            <div className={style.iconContainer} onClick={() => handleButtonClick("/events")}> 
              <div className={style.iconButton}>
                <MdOutlineEmojiEvents size={45} />
              </div>
              <span className={style.iconLabel}>Events</span>
            </div>
            <div className={style.iconContainer} onClick={() => handleButtonClick("/rentals")}> 
              <div className={style.iconButton}>
                <MdOutlineHouse size={45} />
              </div>
              <span className={style.iconLabel}>Rentals</span>
            </div>
            <div className={style.iconContainer} onClick={() => handleButtonClick("/restaurants")}> 
              <div className={style.iconButton}>
                <MdOutlineRestaurant size={45} />
              </div>
              <span className={style.iconLabel}>Restaurants</span>
            </div>
            <div className={style.iconContainer} onClick={() => handleButtonClick("/used")}> 
              <div className={style.iconButton}>
                <MdOutlineShoppingBag size={45} />
              </div>
              <span className={style.iconLabel}>Used Items</span>
            </div>
          </div>
        </div>
      </div>  
    </Fragment>
  );
};

export default Layout;