import React, { useState, useContext, useRef, useEffect } from "react";
import { ChatBubble } from "./ChatBubble";
import { AuthContext } from "../context/AuthContext";
import "./GlobalChatRoom.css";

const UmiuniAIHelperChat = () => {
    const { auth } = useContext(AuthContext);
    const [chats, setChats] = useState([]);
    const [textBoxValue, setTextBoxValue] = useState("");
    const [name, setName] = useState([]);
    const [pfp, setPfp] = useState([]);
    const wsRef = useRef(null);
    const messagesEndRef = useRef(null);

    const initWs = () => {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket("https://ai-api.umiuni.com:8765/run-gemma2b-rag");

            ws.onopen = () => {
                console.log("Connected to WebSocket");
                wsRef.current = ws;
                setChats((prevChats) => [
                    ...prevChats,
                    { content: "", timestamp: new Date().toISOString(), sendOut: false, isAccumulated: true }
                ]);
                resolve();
            };

            ws.onmessage = (event) => {
                console.log("Received message:", event.data);
                setChats((prevChats) => {
                    return prevChats.map((chat, index) => {
                        if (index === prevChats.length - 1 && chat.isAccumulated && event.data != "END_OF_RESPONSE") {
                            return {
                                ...chat,
                                content: `${chat.content}${chat.content ? "\n" : ""}${event.data}`,
                            };
                        }
                        return chat;
                    });
                });
                scrollToBottom();

                if (event.data === "END_OF_RESPONSE") {
                    if (wsRef.current) {
                        wsRef.current.close();
                        wsRef.current = null;
                    }
                }
            };

            ws.onerror = (error) => {
                console.error("WebSocket error:", error);
                reject(error);
            };

            ws.onclose = () => {
                console.log("WebSocket closed");
                wsRef.current = null;
            };

            wsRef.current = ws;
        });
    };

    const fetchChatHistory = async () => {
        try {
          const response = await fetch("https://ai-api.umiuni.com:8765/aihistory");
          if (!response.ok) {
            throw new Error("Failed to fetch chat history");
          }
          const data = await response.json();
          const transformedData = data.map((chat) => {
            let messageContent = chat.message;
            if (messageContent.endsWith("END_OF_RESPONSE")) {
              messageContent = messageContent.slice(0, -"END_OF_RESPONSE".length).trim();
            }
            return {
              content: messageContent,
              timestamp: chat.timestamp,
              sendOut: chat.sender === 1,
            };
          });
    
          setChats(transformedData);
        } catch (error) {
          console.error("Error fetching chat history:", error);
        }
      };
    
    useEffect(() => {
        fetchChatHistory();
        setName((prevName) => ({
            ...prevName,
            "UmiUni_AI_Helper": 'UmiUni AI Helper',
        }));
        setPfp((prevPfp) => ({
            ...prevPfp,
            "UmiUni_AI_Helper": '94NCQ5GiFeXAVwr3alGqY7Zp9xlzpIGu',
        }));
        setTimeout(() => {
            scrollToBottom();
        }, 100);
    }, []);

    const scrollToBottom = () => {
        setTimeout(() => {
        if (messagesEndRef.current && messagesEndRef.current.parentNode) {
            const parentNode = messagesEndRef.current.parentNode;
                parentNode.scrollTop = parentNode.scrollHeight; // Direct scroll adjustment
            }
        }, 0);
    };

    const handleTextBoxChange = (event) => {
        setTextBoxValue(event.target.value);
    };

    const handleSendClick = async () => {
        const messageContent = textBoxValue.trim();
        if (!messageContent) return;

        const timestamp = new Date().getTime();

        const message = {
            content: messageContent,
            timestamp: timestamp,
            sender: auth.userid,
        };

        setChats((prevChats) => [
            ...prevChats,
            { content: messageContent, timestamp, sendOut: true, isAccumulated: false }
        ]);

        if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
            try {
                await initWs();
            } catch (error) {
                console.error("Failed to initialize WebSocket:", error);
                return;
            }
        }

        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify(message));
        } else {
            alert("Connection not open. Attempting to reconnect...");
        }

        // Clear the text box after sending the message
        setTextBoxValue("");
        scrollToBottom();
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSendClick();
        }
    };

    return (
        <div>
            <h1>ChatRoom for UmiUni AI Helper</h1>
            <div className="chatArea">
                {chats.map((chat, index) => (
                    <ChatBubble
                        key={index}
                        content={chat.content}
                        self_flag={!chat.sendOut}
                        timestamp={chat.timestamp}
                        sender={!chat.sendOut && !chat.sendOut ? { pfp: pfp["UmiUni_AI_Helper"], name: name["UmiUni_AI_Helper"] } : undefined}
                    />
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="text-box-container chatInput">
                <input
                    type="text"
                    value={textBoxValue}
                    className="chatInputBox"
                    onChange={handleTextBoxChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Type something..."
                />
                <div className="sendButton" onClick={handleSendClick}>
                    Send
                </div>
            </div>
        </div>
    );
};

export default UmiuniAIHelperChat;
