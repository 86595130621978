import { useState, useEffect } from 'react';

export function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(() => {
    const persistedState = localStorage.getItem(key);
    console.log(`Loading ${key} from storage:`, persistedState);
    return persistedState !== null ? JSON.parse(persistedState) : defaultValue;
  });

  useEffect(() => {
    console.log(`Persisting ${key}:`, state);
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

