import React from "react";
// import "./Post.css";
import { useState } from "react";
import {
  Title,
  Description,
  Prices,
  Buttons,
  ApplyDates,
  EventLocation,
  EventDates,
} from "./BasicContent";

const EventP = () => {
  const [formInfo, setFormInfo] = useState({
    name: "",
    price: "",
    original_price: "",
    description: "",
    applyStartAt: "",
    applyEndAt: "",
    eventStartAt: "",
    eventEndAt: "",
    eventLocation: "",
  });

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };


  return (
    <div>
      <div className="ivu-form-block">
        <Title value={formInfo.name} handleChange={handleChange} />
      </div>
      <div className="ivu-form-block">
        <Prices
          price_value={formInfo.price}
          original_price_value={formInfo.original_price}
          handleChange={handleChange}
        />
      </div>
      <div className="ivu-form-block">
        <ApplyDates
          applyStartAt={formInfo.applyStartAt}
          applyEndAt={formInfo.applyEndAt}
          handleChange={handleChange}
        />

        <EventLocation
          value={formInfo.eventLocation}
          handleChange={handleChange}
        />

        <EventDates
          eventStartAt={formInfo.eventStartAt}
          eventEndAt={formInfo.eventEndAt}
          handleChange={handleChange}
        />
      </div>
      <div className="ivu-form-block">
        <Description value={formInfo.description} handleChange={handleChange} />
      </div>
    </div>
  );
};

export default EventP;
