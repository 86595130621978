import React from 'react';
import { Link } from "react-router-dom";

import './CancelPage.css'; // Ensure the path is correct

const CancelPage = () => {
  return (
    <div className="cancel-page-wrapper">
      <div className="cancel-page-container">
        <div className="card">
          <h1 className="cancelPage_h1">Payment Canceled</h1>
          <p className="cancelPage_p">
            We have canceled your payment.
          </p>
          <Link to="/" className="home-button-link">
            <button className="home-btn">Go Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;
