import React from "react";
import "./Post.css";
import { useState } from "react";
import {
  Title,
  Description,
  Prices,
  Buttons,
  YearAndMileage,
} from "./BasicContent";

const UsedCar = () => {
  const [formInfo, setFormInfo] = useState({
    name: "",
    price: "",
    original_price: "",
    description: "",
    year: "",
    mileage: "",
  });

  /* if (originalPrice !== "") {
        formData.append("original_price", parseInt(originalPrice));
      }
      formData.append("description", description); */

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };


  return (
    <div>
      <div className="ivu-form-block">
        <Title value={formInfo.name} handleChange={handleChange} />
      </div>
      <div className="ivu-form-block">
        <Prices
          price_value={formInfo.price}
          original_price_value={formInfo.original_price}
          handleChange={handleChange}
        />
      </div>
      <div className="ivu-form-block">
        <YearAndMileage
          year={formInfo.year}
          mileage={formInfo.mileage}
          handleChange={handleChange}
        />
      </div>
      <div className="ivu-form-block">
        <Description value={formInfo.description} handleChange={handleChange} />
      </div>
    </div>
  );
};

export default UsedCar;
