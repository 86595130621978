import React from "react";
import "./Post.css";
import { useState } from "react";
import { Title, Description, Prices, Buttons } from "./BasicContent";

const UsedForSale = () => {
  // update input values on screen in real time
  const [formInfo, setFormInfo] = useState({
    name: "",
    price: "",
    original_price: "",
    description: "",
  });

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="ivu-form-block">
        <Title value={formInfo.name} handleChange={handleChange} />
      </div>
      <div className="ivu-form-block">
        <Prices
          price_value={formInfo.price}
          original_price_value={formInfo.original_price}
          handleChange={handleChange}
        />
      </div>
      <div className="ivu-form-block">
        <Description value={formInfo.description} handleChange={handleChange} />
      </div>
    </div>
  );
};

export default UsedForSale;
