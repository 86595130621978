import React from 'react';

const ForumListCard = ({ forum }) => {
  // const { title, user, publish_timestamp, view, reply, content } = topic;
  return (
    <div className="card">
      <h2>{forum.title}</h2>
      <p>Publish Timestamp: {forum.publish_timestamp}</p>
      <p>View Count: {forum.view}</p>
      <p>Reply Count: {forum.reply}</p>
      <p>Username: {forum.user.username}</p>
      <p> Content: {forum.content}</p>
<span className="large-avatar" style={{ backgroundImage: `url("https://oss.umiuni.com/${forum.user.avatar}")` }}></span>    
</div>
  );
};

export default ForumListCard;
