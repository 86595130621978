import React from "react";
import { useContext } from "react";
import style from "./header_searchbar.module.css";
import { AuthContext } from '../context/AuthContext';

const CheckInButton = () => {
    const { auth } = useContext(AuthContext);

    const handleClick = async () => {
        try {
            const response = await fetch('https://umiuni.com:5002/api/user/user_signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: auth.userid,
                    session: auth.session,
                    signin_text: "Checked in for today!" 
                })
            });

            const data = await response.json();
            
            if (data.error) {
                alert(data.error);
             } else {
                console.log(data);
                alert(`Successfully checked in! Balance +1 \n Current balance: ${data.balance} points`);
            }
            
        } catch (error) {
            console.error('Error checking in:', error);
            alert('Failed to check in. Please try again.');
        }
    };

    return (
        <div>
            <button className={style.searchButton} onClick={handleClick}>
                Check In
            </button>
        </div>
    );
};

export default CheckInButton;