import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import style from './user_page.module.css'; 
import UserProfile from './User_profile';
import UserChangePassword from './User_change_password';
import UserManage from './User_manage';
import UserContacts from './User_Contacts';
import Logout from '../logout/Logout';

import { AuthContext } from "../context/AuthContext";
import axios from 'axios';

const UserPage = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("");
  const [menuIndex, setMenuIndex] = useState(0);
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const { auth } = useContext(AuthContext);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/user/get`, {
      params: {
        "userid": auth.userid
      }
    })
    .then((response) => {
      setUserData(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const content = () => {
    switch (selectedMenu) {
      case "userProfile":
        return <UserProfile />;
      case "userPassword":
        return <UserChangePassword />;
      case "userManage":
        return <UserManage />;
      case "userContacts":
        return <UserContacts />;
      default:
        return <UserProfile />;
    }
  }

  const handleMenu = (component, index) => {
    setMenuIndex(index);
    setSelectedMenu(component);
    console.log();
  }

  useEffect(() => {
    setSelectedMenu(location?.state?.menuElement || '');
  }, [location?.state?.menuElement]);
  
  return (
    <div className={`${style.userPage} ${isMobile ? style.mobile : ''}`}>
      <div className={style.userInterface}>
        <ul className={`${style.userMenu} ${isMobile ? style.userMenuHorizontal : ''}`}>
          <li onClick={() => handleMenu("userProfile", 0)} className={`${menuIndex === 0 && style.menuSelected}`}>My Account</li>
          {!userData?.email.endsWith("@gmail.com") && (
            <li onClick={() => handleMenu("userPassword", 1)} className={`${menuIndex === 1 && style.menuSelected}`}>Change Password</li>
          )}
          <li onClick={() => handleMenu("userManage", 2)} className={`${menuIndex === 2 && style.menuSelected}`}>Manage</li>
          <li onClick={() => handleMenu("userContacts", 3)} className={`${menuIndex === 3 && style.menuSelected}`}>Contacts</li>
          <Logout />
        </ul>
      </div>
      <div className={style.userContent}>
        {content()}
      </div>
    </div>
  );
}

export default UserPage;
