import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import style from "./detail.module.css";
import Carousel from "react-bootstrap/Carousel";
import { AuthContext } from "../context/AuthContext";

const Detail = () => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemDetails, setItemDetails] = useState(null);
  const [itemActions, setItemActions] = useState(null);
  const [sellConfirm, setSellConfirm] = useState(0);
  const [sellMessage, setSellMessage] = useState("Mark as sold");
  const { iid } = useParams();
  const url = `${process.env.REACT_APP_API_URL}api/item/get?iid=${iid}`;
  const [isCooldown, setIsCooldown] = useState(false);

  const getAxios = () => {
    axios
      .get(url)
      .then((response) => {
        setItemDetails(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAxios();
  }, [iid]);

  const shareDetails = async () => {
    if (isCooldown) {
      alert("Please wait a minute before sharing again.");
      return;
    }

    const shareUrl = `https://umiuni.com/detail/${iid}`;
    const itemName = itemDetails?.item?.name || "this item";
    const itemDescription = itemDetails?.item?.description || "Check out this amazing item!";
    const itemType = itemDetails?.item?.type || "item";

    try {
      if (navigator.share) {
        await navigator.share({
          title: `Check out this ${itemType}: ${itemName}`,
          text: itemDescription,
          url: shareUrl,
        });
        alert("Share successful! Adding 1 membership point.");
      } else {
        await navigator.clipboard.writeText(shareUrl);
        alert("Link copied to clipboard! Adding 1 membership point.");
      }

      // Call API to add membership point
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/user/increase_balance`,
        {
          user_id: auth.userid,
          secret_password: "my_secret_password", // Replace with actual secret password
          increase_balance: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Start cooldown
      setIsCooldown(true);
      setTimeout(() => {
        setIsCooldown(false); // Cooldown ends after 1 minute
      }, 60000);
    } catch (error) {
      console.error("Error during sharing or API call:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const nameDisplay = () => {
    if (itemDetails === null) {
      return;
    }
    return <h2>{itemDetails.item.name}</h2>;
  };

  const priceDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    let content = [
      <div className={style.priceInfo}>${itemDetails.item.price}</div>,
    ];

    if (itemDetails.item.original_price) {
      content.push(
        <div className={style.priceOriginal}>
          ${itemDetails.item.original_price}
        </div>
      );
    }

    return content;
  };

  const pictureDisplay = () => {
    if (itemDetails === null) {
      return null;
    }

    return itemDetails.item.photo.map((photo, index) => (
      <div
        key={index}
        onClick={() => setCurrentIndex(index)}
        className={style.carouselItemContainer}
        style={{
          boxShadow:
            index === currentIndex
              ? "0 0 7px rgba(0, 178, 255, 1)"
              : "",
        }}
      >
        <img
          src={`https://oss.umiuni.com/${photo}`}
          className={style.carouselItem}
          alt="Item"
        />
      </div>
    ));
  };

  const tagDisplay = () => {
    if (itemDetails === null) {
      return null;
    }

    return itemDetails.item.tag.map((tag, index) => (
      <button key={index} className={style.tagButton}>
        {tag}
      </button>
    ));
  };

  const contactInfoDisplay = () => {
    if (itemDetails === null) {
      return null;
    }
    return (
      <>
        <div>{itemDetails.item.contact}</div>
        <div>{itemDetails.seller.email}</div>
      </>
    );
  };

  const publisherDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    const publisherUsername = itemDetails.seller.username;

    return (
      <a href="/" className={style.anchor}>
        <div className={style.publisherContainer}>
          <img
            src={`https://oss.umiuni.com/${itemDetails.seller.avatar}`}
            className={style.sellerAvatar}
            alt="Publisher"
          />
          <div>{publisherUsername}</div>
        </div>
      </a>
    );
  };

  const descriptionDisplay = () => {
    if (itemDetails === null) {
      return null;
    }
    return itemDetails.item.description;
  };

  const actionButtonsDisplay = () => {
    if (itemDetails === null) return;

    let buttons = [];

    if (itemDetails.item.status === 1 && auth.userid === itemDetails.seller.userid) {
      buttons.push(
        <button
          className={style.soldButton}
          onClick={() => setSellConfirm((prev) => prev + 1)}
        >
          {sellMessage}
        </button>
      );
    } else {
      buttons.push(null);
    }

    setItemActions(buttons);
  };

  useEffect(() => {
    if (sellConfirm === 1) {
      setSellMessage("Confirm");
    } else if (sellConfirm === 2) {
      // Mark as sold logic
      axios
        .post(`${process.env.REACT_APP_API_URL}api/item/set`, {
          userid: auth.userid,
          session: auth.session,
          iid: iid,
          status: 2,
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => console.error(error));
    }
  }, [sellConfirm]);

  useEffect(() => {
    if (itemDetails) {
      actionButtonsDisplay();
    }
  }, [itemDetails, sellConfirm, sellMessage]);

  return (
    <>
      <div className={style.detailPage}>
        <div className={style.imageSelectPanel}>{pictureDisplay()}</div>
        <div className={style.carouselContainer}>
          <Carousel
            activeIndex={currentIndex}
            onSelect={setCurrentIndex}
            className={style.carousel}
            prevIcon={<span className={`carousel-control-prev-icon ${style.carouselArrow}`} />}
            nextIcon={<span className={`carousel-control-next-icon ${style.carouselArrow}`} />}
          >
            {itemDetails?.item.photo.map((photo, index) => (
              <Carousel.Item key={index}>
                <img
                  src={`https://oss.umiuni.com/${photo}`}
                  alt={`Slide ${index}`}
                  className={style.carouselImage}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className={style.infoDiv}>
          <div className={style.infoInternal}>
            {nameDisplay()}
            {itemActions}
          </div>
          <div className={style.infoInternal}>
            <div className={style.priceContainer}>{priceDisplay()}</div>
          </div>
          <div className={style.infoInternal}>{tagDisplay()}</div>
          <div className={style.infoInternal}>
            <div>Published by</div>
            {publisherDisplay()}
          </div>
          <div className={style.infoInternal}>
            <div>Description</div>
            {descriptionDisplay()}
          </div>
          <div className={style.infoInternal}>
            <div>Contact Information</div>
            {contactInfoDisplay()}
          </div>
          <div className={style.infoInternal}>
            <button
              className={style.shareButton}
              onClick={shareDetails}
              disabled={isCooldown} 
            >
              Share
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
