import React from "react";
import "./Post.css";
import "../upload/Upload.css";
import Upload from "../upload/Upload";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import Login from "../login/Login";
import { useState } from "react";
import UsedForSale from "./UsedForSale";
import UsedCar from "./UsedCar";
import EventP from "./Event";
import Dining from "./Dining";
import Housing from "./Housing";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Post = () => {
  const { auth } = useContext(AuthContext);
  const { userid, session } = auth;
  const [selectedButton, setSelectedButton] = useState(null);
  const navigate = useNavigate();

  if (!userid) {
    return <Login />;
  }

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formElement = document.getElementById("post-form");
    const formData = new FormData();

    formData.append("userid", userid);
    formData.append("session", session);
    formData.append("type", selectedButton || "used");

    const formInputs = formElement.elements;
    for (let i = 0; i < formInputs.length; i++) {
      const input = formInputs[i];

      // Check if the input has a non-empty value and is not disabled
      if (input.value !== "") {
        // Append the input to the FormData
        formData.append(input.name, input.value);
      }
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}api/item/set`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("post success", res);
        navigate(`/detail/${res.data.iid}`);
      })
      .catch((rej) => {
        console.log("post failure", rej);
      });
    
  };

  const handleReset = () => {
    const formElement = document.getElementById("post-form");
    const formInputs = formElement.elements;
    for (let i = 0; i < formInputs.length; i++) {
      const input = formInputs[i];
      input.value = "";
    }
    setSelectedButton(null);

    const clearPhotoButton = document.getElementById("clearPhotos");
    clearPhotoButton.click();
  };

  return (
    <div className="page-container">
      <form
        id="post-form"
        className="content-container"
        onSubmit={handleSubmit}
      >
        <div className="upload-container">
          <Upload userid={userid} session={session} />
        </div>
        <div className="card-container">
          <h1 className="title">发布</h1>
          <div className="ivu-form-block">
            <label className="ivu-form-item-label">
              <b className="required-marker" style={{ color: "red" }}>
                *{" "}
              </b>
              分类
            </label>
            <div className="ivu-form-item-content">
              <a>违禁品清单</a>
              {/* TODO:考虑改为select + options */}
              <div className="ivu-btn-group">
                <button
                  key="used"
                  className={
                    selectedButton === "used"
                      ? "ivu-btn-primary ivu-btn"
                      : "ivu-btn ivu-btn-ghost"
                  }
                  type="button"
                  onClick={() => handleButtonClick("used")}
                >
                  二手闲置
                </button>
                <button
                  key="house"
                  className={
                    selectedButton === "house"
                      ? "ivu-btn-primary ivu-btn"
                      : "ivu-btn ivu-btn-ghost"
                  }
                  type="button"
                  onClick={() => handleButtonClick("house")}
                >
                  租房信息
                </button>
                <button
                  key="car"
                  className={
                    selectedButton === "car"
                      ? "ivu-btn-primary ivu-btn"
                      : "ivu-btn ivu-btn-ghost"
                  }
                  type="button"
                  onClick={() => handleButtonClick("car")}
                >
                  二手车
                </button>
                <button
                  key="event"
                  className={
                    selectedButton === "event"
                      ? "ivu-btn-primary ivu-btn"
                      : "ivu-btn ivu-btn-ghost"
                  }
                  type="button"
                  onClick={() => handleButtonClick("event")}
                >
                  活动
                </button>
                <button
                  key="dining"
                  className={
                    selectedButton === "dining"
                      ? "ivu-btn-primary ivu-btn"
                      : "ivu-btn ivu-btn-ghost"
                  }
                  type="button"
                  onClick={() => handleButtonClick("dining")}
                >
                  周边餐馆
                </button>
              </div>
            </div>
          </div>

          {selectedButton === "used" && <UsedForSale />}

          {selectedButton === "house" && <Housing />}

          {selectedButton === "car" && <UsedCar />}

          {selectedButton === "event" && <EventP />}

          {/* merchant_house table没有地址信息，填了没用；此外，觉得post餐馆信息不应该放在sell里面； */}
          {selectedButton === "dining" && <Dining />}

          {selectedButton === null && <UsedForSale />}

          <div className="btn-set ivu-form-item">
            <div className="btn-row">
              <div className="ivu-col-span-12">
                <button
                  className="ivu-btn ivu-btn-ghost ivu-btn-long"
                  type="button"
                  // onClick={props.saveAsDraft}
                >
                  保存为草稿
                </button>
              </div>
              <div className="ivu-col-span-12">
                <button
                  className="ivu-btn ivu-btn-ghost ivu-btn-long"
                  // type="reset"
                  onClick={handleReset}
                >
                  重置
                </button>
              </div>
            </div>
            <div className="ivu-row">
              <div className="ivu-col-span-24">
                <button
                  id="submit-button"
                  className="ivu-btn ivu-btn-primary ivu-btn-long"
                  type="submit"
                >
                  发布
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Post;
