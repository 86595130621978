import React, { useEffect, useState, useContext, useRef } from "react";
import { io } from "socket.io-client";
import { ChatBubble } from "./ChatBubble";
import { AuthContext } from "../context/AuthContext";
import "./GlobalChatRoom.css"; // Import the CSS file
const config_chat_backend_url = "https://ai-api.umiuni.com:8183";

const GlobalChatRoom = () => {
  const [chats, setChats] = useState([]);
  const [textBoxValue, setTextBoxValue] = useState("");
  const { auth } = useContext(AuthContext);
  const [name, setName] = useState([]);
  const [pfp, setPfp] = useState([]);
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    setTimeout(() => {
	if (messagesEndRef.current && messagesEndRef.current.parentNode) {
		const parentNode = messagesEndRef.current.parentNode;
	      	parentNode.scrollTop = parentNode.scrollHeight; // Direct scroll adjustment
    	}
    }, 0);
  };
  useEffect(() => {
    console.log("in useEffect");
    
    socketRef.current = io(`${config_chat_backend_url}/`, {
      path: "/socket.io/",
      transports: ["websocket"],
      secure: true,
      withCredentials: true,
      rejectUnauthorized: false, // Accept self-signed certificates
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to the server.");
      socketRef.current.emit("join_room", { room: "testRoom" });
      
      fetch(`${config_chat_backend_url}/history`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data["testRoom"]);
        // Initialize socket connection
        setChats(data["testRoom"]);
        const test_room_history = data["testRoom"];
        test_room_history.map((value, i) => {
          value.content = value.message;
          value.sendOut = value.sender === auth.userid;

          fetch(
            `${process.env.REACT_APP_API_URL}api/user/get?userid=${value.sender}`
          )
            .then((response) => response.json())
            .then((result) => {
              setName((prevName) => ({
                ...prevName,
                [value.sender]: result.username || value.sender,
              }));
              setPfp((prevPfp) => ({
                ...prevPfp,
                [value.sender]: result.avatar || '94NCQ5GiFeXAVwr3alGqY7Zp9xlzpIGu',
              }));
            })
            .catch((error) => console.error(error));
        });
      });
	setTimeout(() => {
            scrollToBottom();
        }, 500);
    });

    socketRef.current.on("new_message", (msg) => {
      console.log("New message received:", msg);
      const parsedMessage = {
        sendOut: msg.sender === auth.userid,
        content: msg.message,
        timestamp: msg.timestamp
      };
      setChats((prevChats) => [...prevChats, parsedMessage]);
      scrollToBottom();
    });

    socketRef.current.on(
      "disconnect",
      (reason) => {
        console.log("Disconnected from the server.", reason);
        // Reconnect logic
        if (reason === "io server disconnect") {
          // The disconnection was initiated by the server, reconnect manually
          socketRef.current.connect();
        }
      }, []
    );

    socketRef.current.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });

    socketRef.current.on("reconnect_attempt", (attempt) => {
      console.log(`Reconnect attempt ${attempt}`);
    });

    socketRef.current.on("reconnect_failed", () => {
      console.error("Reconnect failed");
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket error:", error);
    });

    // Cleanup on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [auth.userid]); // Ensure the effect runs only once and depends on auth.userid

  const handleTextBoxChange = (event) => {
    setTextBoxValue(event.target.value);
  };

  const handleSendClick = () => {
    const messageContent = textBoxValue.trim();
    if (!messageContent) return;

    const timestamp = new Date().getTime();

    // Use socketRef.current to emit the message
    if (auth.userid) {
      socketRef.current.emit("send_message", {
        room: "testRoom",
        message: messageContent,
        sender: auth.userid,
        timestamp,
      });
    } else {
      alert("Please login first!");
    }

    // Clear the text box after sending the message
    setTextBoxValue("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendClick();
    }
  };

  return (
    <div>
      <div className="chatArea">
        {chats.map((chat, index) => (
          <ChatBubble
            key={index}
            content={chat.content}
            self_flag={!chat.sendOut}
            timestamp={chat.timestamp}
            sender={chat.sender !== auth.userid ? { pfp: pfp[chat.sender], name: name[chat.sender], senderId: chat.sender } : undefined}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="text-box-container chatInput">
        <input
          type="text"
          value={textBoxValue}
          className="chatInputBox"
          onChange={handleTextBoxChange}
          onKeyDown={handleKeyPress}
          placeholder="Type something..."
        />
        <div className="sendButton" onClick={handleSendClick}>
          Send
        </div>
      </div>
    </div>
  );
};

export default GlobalChatRoom;
