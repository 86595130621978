import style from './Contactus.module.css';
import {Fragment} from 'react';

const Contactus = (props) => {
  return(
    <>
      <div className = {style.container}>
        <h1>Contact Us - Report Bugs</h1>
        <p>email: admin@umiuni.com</p>
        <p>wechat: tiantian20170427</p>
      </div>
    </>
  );
};


export default Contactus;

