import axios from 'axios';
import { useRef, useState, useEffect, useContext } from 'react';
import { AuthContext } from "../context/AuthContext";
import Header from "../header/Header";
import { useParams } from "react-router-dom";
import ForumListCard from '../forumlist/ForumListCard';


const ForumDetail = () => {
  const { ftid } = useParams();
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [forums, setForums] = useState([]);
  const [userid, setUserId] = useState('');
  const [session, setSession] = useState('');
  const [fpid, setfpid] = useState('');

  var axios = require('axios');
  useEffect(() => {
	const storedUserId = localStorage.getItem('userid');
  const storedSession = localStorage.getItem('session');
  const successState = localStorage.getItem('success');
  // Check if both values are present before setting the state
  if (storedUserId && storedSession) {
    setUserId(storedUserId);
    setSession(storedSession);
    setSuccess(successState);
  }
      userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [user, pwd])

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/forum/list_post?ftid=${ftid}&userid={userid}`);
          const responseData = response.data.posts;
	  const dataArray = Array.isArray(responseData) ? responseData : Array.from(responseData);
          setForums(dataArray);
	} catch (error) {
          console.log(error);
          setErrMsg('Error fetching forums');
        }
      };

      fetchData();
  }, [ftid, userid])


  const handleForumSubmit = async (e) => {
      e.preventDefault();
      var data = {
          userid: userid,
          session: session,
          ftid: ftid,
          content: document.getElementById('postText').value
      };
      var config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}api/forum/set_post`,
          data: data
      };
      try {
   	 const response = await axios(config);
    	 console.log(JSON.stringify(response.data));
    	 setfpid(response.data.fpid);
    // window.alert("The post has been added successfully!");
window.location.reload();
  } catch (error) {
    console.log(error);
  }
  }

  const handleSubmit = async (e) => {
      e.preventDefault();
          
      var data = JSON.stringify({
        "username": user,
        "password": pwd,
      });

      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}api/user/login`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };


      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        const userid = response.data.userid;
        const session = response.data.session;
        const school_abbr = response.data.school_abbr;
        setAuth({userid, session, school_abbr});
        setUser('');
        setPwd('');
	setUserId(userid);
	setSession(session);
        setSuccess(true);
localStorage.setItem('userid', userid); // Replace 'userId' with the actual user ID
localStorage.setItem('session', session); // Replace 'sessionToken' with the actual session token
localStorage.setItem('success', true);
        
})
      .catch(function (error) {
        console.log(error);
        if (!error?.response) {
          setErrMsg('No Server Response');
        } else if (error.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (error.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Login Failed');
        }
        errRef.current.focus();
      });
    }



    return (
        <>
	    <Header />
            {success ? (
                <section>
                    <h1>You are logged in!</h1>
                    <br />
                    <p>
                        <a href="#">Go to Home</a>
                    </p>
		<div>
		{forums.map((forum) => (
          	  <ForumListCard forum={forum} />
      		))}
		</div>
		<div id="postContainer">
		    <form onSubmit={handleForumSubmit}>
    			<textarea id="postText" placeholder="Write your response here..."></textarea>
    			<button id="postButton" type="submit">Post</button>
		    </form>
  		</div>
		</section>
		): (
                <div id = "login_content">
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Sign In</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="on"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                        />
                        <button>Sign In</button>
                    </form>
                    <p>
                        Need an Account?<br />
                        <span className="line">
                            {/*put router link here*/}
                            <a href="/verifyemail">Register</a>
                        </span>
                    </p>
                </div>
            )}
        </>
    )
}

export default ForumDetail;
