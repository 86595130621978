import "boxicons/css/boxicons.min.css";
import ButtonLoader from "../ButtonLoader/index";
import { Fragment } from "react";
// import WithNavigationNextStepButton from "../NextStep/nextstep";
import SignUp from "./sign_up";
import classes from "./verify_email.module.css";
import { useRef, useState, useEffect, useContext } from "react";
import Header from "../header/Header";
import style from "./verify_email.module.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const [email, setEmail] = useState("");
  const [verification_code, setVerificationCode] = useState("");
  const navigate = useNavigate();

  // Check if the email is valid, but not necessary for education email
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailBlur = () => {
    if (!isValidEmail(email)) {
      alert("Invalid email. Please enter a valid email address.");
    }
  };

  const handleVerificationCodeChange = (e) => {
    const inputCode = e.target.value;
    setVerificationCode(inputCode);
  };

  const handleNextStep = () => {
    var data = JSON.stringify({
      email: email,
      verification_code: verification_code
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}api/user/pre_register`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    console.log("data:",data);

    axios.request(config)
      .then(function (response) {
        if (response.data.result) {
          alert("Verification successful! Your email has been verified.");
          navigate(`/signup/${encodeURIComponent(email)}/${verification_code}`);
        } else if (response.data.error !== undefined) {
          if (response.data.error === "") {
            alert("Verification error: The verification code is incorrect.");
          } else {
            alert("Verification error: " + response.data.error);
          }
        } else {
          alert("Unexpected response from the server. Please try again later.");
        }
      })
      .catch(function (error) {
        console.error('Error during verification:', error);
        alert('Verification failed due to a network or server error. Please try again.');
      });
  };

  return (
    <div className={style.registrationForm}>
      <div className={style.formGroup}>
        <div className={style.outer}>
          <div className={style.registerFrame}>
            <h1>Verify Email</h1>
            <div className={style.formGroup}>
              <label>Email:</label>
              <input
                type="text"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                required
              />
              <ButtonLoader email={email} /> 
            </div>
            <div className={style.formGroup}>
              <label>Verification Code:</label>
              <input
                type="text"
                value={verification_code}
                onChange={handleVerificationCodeChange}
                required
              />
              <button onClick={handleNextStep}>
                Next Step
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;

