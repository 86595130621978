import { useEffect, useState } from 'react';
import { usePersistedState } from './usePersistedState';
import { createContext } from 'react';

export const AuthContext = createContext({}); // initialize context with an empty object

const AuthProvider = ({ children }) => {

  const [auth, setAuth] = usePersistedState('auth', {});

  const logout = () => {
    console.log("Logging out");
    setAuth({});
  }

  const authContextValue = {
    auth,
    setAuth,
    logout
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

