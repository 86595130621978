import React from "react";
import { useState, useRef, useEffect } from "react";
import style from "./header_searchbar.module.css";
import { useNavigate } from "react-router-dom";


const TopUpButton = () => {

	const navigate = useNavigate();
	const handleClick = () => {
        
            let url = "/TopUp";
            navigate(url);
        }
return (	
	<div>
	<button className = {style.searchButton} onClick = {handleClick}>TopUp</button>
	</div>
);

}

export default TopUpButton;
