import React from "react";
import "./Post.css";

export const Title = (props) => {
  return (
    <div className="ivu-form-item ivu-form-item-required">
      <label className="ivu-form-item-label" htmlFor="name">
        <b className="required-marker" style={{ color: "red" }}>
          *{" "}
        </b>
        标题
      </label>
      <div className="ivu-form-item-content">
        <input
          id="name"
          name="name"
          className="ivu-input"
          type="text"
          placeholder="What are you selling?"
          value={props.value}
          onChange={props.handleChange}
          required
          aria-required
        />
      </div>
    </div>
  );
};

export const Description = (props) => {
  return (
    <div className="ivu-form-item">
      <label className="ivu-form-item-label" htmlFor="description">
        <b className="required-marker" style={{ color: "red" }}>
          *{" "}
        </b>
        详细描述
      </label>
      <div className="ivu-form-item-content">
        <textarea
          id="description"
          name="description"
          className="ivu-input"
          placeholder="More description about your item? E.g. condition, size, etc..."
          rows="2"
          value={props.value}
          onChange={props.handleChange}
          required
          aria-required
        ></textarea>
      </div>
    </div>
  );
};

export const Prices = (props) => {
  return (
    <div className="ivu-row ivu-form-item">
      <div className="ivu-col ivu-col-span-11">
        <div className="ivu-form-item-required">
          <label htmlFor="price">
            <b className="required-marker" style={{ color: "red" }}>
              *{" "}
            </b>
            价格
          </label>
          <input
            type="number"
            id="price"
            name="price"
            autoComplete="off"
            className="ivu-input"
            value={props.price_value}
            onChange={props.handleChange}
            required
            aria-required
          />
        </div>
      </div>
      <div className="ivu-col ivu-col-span-11">
        <div className="">
          <label className="ivu-form-item-label" htmlFor="original_price">
            原价
          </label>
          <input
            type="number"
            id="original_price"
            name="original_price"
            autoComplete="off"
            className="ivu-input"
            value={props.original_price_value}
            onChange={props.handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export const YearAndMileage = (props) => {
  return (
    // TODO: Year改为select & options
    <div className="ivu-row ivu-form-item">
      <div className="ivu-col ivu-col-span-11">
        <div className="ivu-form-item-required">
          <label htmlFor="year">Year</label>
          <input
            type="number"
            id="year"
            name="year"
            min={1900}
            max={new Date().getFullYear()}
            autoComplete="off"
            className="ivu-input number-input"
            value={props.year}
            onChange={props.handleChange}
          />
        </div>
      </div>
      <div className="ivu-col ivu-col-span-11">
        <div className="">
          <label className="ivu-form-item-label" htmlFor="mileage">
            Mileage
          </label>
          <input
            type="number"
            id="mileage"
            name="mileage"
            autoComplete="off"
            className="ivu-input number-input"
            value={props.mileage}
            onChange={props.handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export const Address = (props) => {
  return (
    <div>
      <label className="ivu-form-item-label" htmlFor="address">
        Address
      </label>
      <div className="ivu-form-item-content">
        <input
          id="address"
          name="address"
          className="ivu-input"
          type="text"
          placeholder="Enter a location"
          value={props.value}
          onChange={props.handleChange}
        />
      </div>
    </div>
  );
};

export const EventLocation = (props) => {
  return (
    <div className="ivu-form-item ivu-form-item-required">
      <label className="ivu-form-item-label" htmlFor="eventLocation">
        活动地址
      </label>
      <div className="ivu-form-item-content">
        <input
          id="eventLocation"
          name="eventLocation"
          className="ivu-input"
          type="text"
          placeholder="Enter a location"
          value={props.value}
          onChange={props.handleChange}
        />
      </div>
    </div>
  );
};

export const Floorplan = (props) => {
  return (
    <div className="ivu-form-item">
      <label className="ivu-form-item-label" htmlFor="bedroom">
        Floorplan
      </label>
      <select
        id="bedroom"
        name="bedroom"
        className="ivu-input"
        value={props.value}
        onChange={props.handleChange}
      >
        {/* TODO: Check what bedroom value should actually be */}
        <option value="0">Studio</option>
        <option value="2">2 bedrooms</option>
        <option value="3">3 bedrooms</option>
        <option value="4">4 bedrooms</option>
        <option value="5">5 bedrooms</option>
        <option value="1">House</option>
      </select>
    </div>
  );
};

export const MovingDates = (props) => {
  return (
    <div className="ivu-row">
      <div className="ivu-col ivu-col-span-11">
        <div>
          <label htmlFor="movein_at">Move-In Date</label>
          <input
            type="date"
            id="movein_at"
            name="movein_at"
            min="2023-01-01"
            value={props.movein_at}
            onChange={props.handleChange}
            autoComplete="off"
            className="ivu-input number-input"
          />
        </div>
      </div>
      <div className="ivu-col ivu-col-span-11">
        <div>
          <label className="ivu-form-item-label" htmlFor="moveout_at">
            Move-Out Date
          </label>
          <input
            type="date"
            id="moveout_at"
            name="moveout_at"
            min={props.movein_at}
            value={props.moveout_at}
            onChange={props.handleChange}
            autoComplete="off"
            className="ivu-input number-input"
          />
        </div>
      </div>
    </div>
  );
};

export const ApplyDates = (props) => {
  return (
    <div className="ivu-row">
      <div className="ivu-col ivu-col-span-11">
        <div className="ivu-form-item-required">
          <label htmlFor="applyStartAt">报名开始时间</label>
          <input
            type="date"
            id="applyStartAt"
            name="applyStartAt"
            min="2023-01-01"
            value={props.applyStartAt}
            onChange={props.handleChange}
            autoComplete="off"
            className="ivu-input number-input"
          />
        </div>
      </div>
      <div className="ivu-col ivu-col-span-11">
        <div className="">
          <label className="ivu-form-item-label" htmlFor="applyEndAt">
            报名截止时间
          </label>
          <input
            type="date"
            id="applyEndAt"
            name="applyEndAt"
            min={props.applyStartAt}
            value={props.applyEndAt}
            onChange={props.handleChange}
            autoComplete="off"
            className="ivu-input number-input"
          />
        </div>
      </div>
    </div>
  );
};

export const EventDates = (props) => {
  return (
    <div className="ivu-row">
      <div className="ivu-col ivu-col-span-11">
        <div className="ivu-form-item-required">
          <label htmlFor="eventStartAt">活动开始时间</label>
          <input
            type="date"
            id="eventStartAt"
            name="eventStartAt"
            min="2023-01-01"
            value={props.eventStartAt}
            onChange={props.handleChange}
            autoComplete="off"
            className="ivu-input number-input"
          />
        </div>
      </div>
      <div className="ivu-col ivu-col-span-11">
        <div className="">
          <label className="ivu-form-item-label" htmlFor="eventEndAt">
            活动结束时间
          </label>
          <input
            type="date"
            id="eventEndAt"
            name="eventEndAt"
            min={props.eventStartAt}
            value={props.eventEndAt}
            onChange={props.handleChange}
            autoComplete="off"
            className="ivu-input number-input"
          />
        </div>
      </div>
    </div>
  );
};

export const Buttons = (props) => {
  return (
    <div className="btn-set ivu-form-item">
      <div className="btn-row">
        <div className="ivu-col-span-12">
          <button
            className="ivu-btn ivu-btn-ghost ivu-btn-long"
            type="button"
            // onClick={props.saveAsDraft}
          >
            保存为草稿
          </button>
        </div>
        <div className="ivu-col-span-12">
          <button
            className="ivu-btn ivu-btn-ghost ivu-btn-long"
            type="reset"
            onClick={props.clearContent}
          >
            重置
          </button>
        </div>
      </div>
      <div className="ivu-row">
        <div className="ivu-col-span-24">
          <button
            id="submit-button"
            className="ivu-btn ivu-btn-primary ivu-btn-long"
            type="submit"
          >
            发布
          </button>
        </div>
      </div>
    </div>
  );
};
