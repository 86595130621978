import classes from './Header.module.css';
import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from '../context/AuthContext';
import HeaderDropdown from './Header_dropdown';
import HeaderRegister from './Header_register';
import HeaderSearchbar from './Header_searchbar';
import umiunilogo from '../../umiunilogo.jpg'
import { useNavigate } from 'react-router-dom';
//import HeaderForumButton from './header/Header_forumbutton';
import HeaderDonateButton from './Header_donatebutton';
import HeaderTopUpButton from './Header_topupbutton';
import HeaderSchoolDropdown from './Header_schooldropdown';
import HeaderChatRoomButton from './Header_chatroombutton';
import HeaderCheckInButton from './Header_checkinbutton';

const Header = (props) => {
    const { auth } = useContext(AuthContext);

    const [isMobile, setIsMobile] = useState(false);
    const [secondHeader, setSecondHeader] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();   

    const handleHomePage = () => {
        console.log("awfawef");
        navigate('/');
    }
    const handleChat = () => {
        navigate('/randomchatroom');
    }
    const handleForum = () => {
        navigate('/forumlist/16/15');
    }
    const handleDonate = () => {
        navigate('/donate/payment');
    }
    const handlePayment = () => {
        navigate('/topup');
    }
    
    useEffect(() => {
        const checkWindowSize = () => {
            setIsMobile(window.innerWidth <= 768);
            setSecondHeader(window.innerWidth <= 1138);
        };
        checkWindowSize();
        window.addEventListener("resize", checkWindowSize);

        return () => {
            window.removeEventListener("resize", checkWindowSize);
        };
    }, [])

    return (
        <Fragment>
            <header className={`${classes.header} ${isMobile || secondHeader ? classes.expandedHeader : ''}`} ref={dropdownRef}>
                <div>
                    <img src={umiunilogo} className={classes.logo} onClick={handleHomePage} />
                </div>
                {/*<div className={classes.searchbar}>
                     <HeaderSearchbar />
                 </div>
                 <HeaderSchoolDropdown />*/}
                <div className={classes.headerButton}>
                    <HeaderChatRoomButton />
                    <HeaderDonateButton />
                    <HeaderTopUpButton />
                    <HeaderCheckInButton />
                </div>
                {auth.userid && auth.vip && <span className={classes.vipBadge}>VIP {auth.vip}</span>}
                <div className={classes.user}>
                    {auth.userid ? (
                        <div className={classes.userContainer}>
                            <HeaderDropdown />
                        </div>
                    ) : (
                        <HeaderRegister />
                    )}
                </div>
            </header>
        </Fragment>
    );

};

export default Header;
