import {NavLink} from 'react-router-dom';
import Contactus from './UmiUni_Team/Contactus';
import Helpabout from './UmiUni_Team/Helpabout';
import Newhire from './UmiUni_Team/Newhire';
import React from 'react';
import {Fragment} from "react";
import style from './footer.module.css';


function Footer(props) {
  return (
    <Fragment>
      <footer className = {style.footer}>
        <br></br>
        <div>
          <NavLink to="/">Home </NavLink>
          <NavLink to="/Helpabout">About Us</NavLink>
          <NavLink to="/contact-us" style={({ isActive }) => ({ 
          color: isActive ? 'red' : 'black' })}>
            Contact Us
          </NavLink>
          <NavLink to = "/Newhire">Hire Info</NavLink>
          <NavLink to = "/FAQ">Common Questions</NavLink>
        </div>
	      <div className="copyright">
          &copy; UmiUni.com, 2016-2040.
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
