import {Fragment} from "react";
import style from './Helpabout.module.css';


const Helpabout = props => {
    return(
      <Fragment>
<div className = {style.container}>
    <h1>About UmiUni</h1>
    <h1>UmiUni Alumni Network is an alumni app created for North American alumni.</h1>
    <p>Campus life, with you and me!</p>
    <p>UmiUni combines second-hand trading, apartment subleasing, local restaurant information near university towns, social dining events, and alumni job referrals in one platform.</p>
    <p>The UmiUni North American Alumni Network was co-founded by alumni teams from various U.S. universities to serve the large community of university students and alumni in the U.S.</p>
    <p>Officially launched since 2016.</p>
    <h1>Current UmiUni Team Members</h1>
    <p>Alumni from UIUC, NYU, University of Maryland, Purdue, CMU, UCLA, and others</p>
    <p>We also have support from alumni engineers currently working at Facebook, LinkedIn, Apple, Google, and Uber.</p>
    <h1>Platforms Supported by UmiUni:</h1>
    <p>WeChat Mini Program, iOS App, Web Version</p>
    <h1>UmiUni Talent Recruitment (software, business):</h1>
    <p>Please send your resume to admin@umiumi.com</p>
</div>
      </Fragment>
    );
};

export default Helpabout;

