import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext"; 
import style from "./password_reset.module.css";


const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [authentificationKey, setAuthentificationKey] = useState("");
  const [displayField, setDisplayField] = useState(false);
//  const [userId, setUserId] = useState("");
//  const [sessionId, setSessionId] = useState("");

  const axios = require("axios");
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);

  const handleEmailSubmit = () => {

    let data = JSON.stringify({
      email: email,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}api/user/forget_password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setDisplayField(true);
        alert("Email Sent"); // Add this line to show the alert
      })
      .catch((error) => {
        console.log(error);
      });

    if (displayField) {
      console.log("disPlayField = true");
    }
  };

  const handleAuthentificationSubmit = () => {

    const userid = authentificationKey.substring(0, 16);
    const session = authentificationKey.substring(16);



    let data = JSON.stringify({
      userid: userid,
      session: session,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/verify_session`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    
    axios(config)
      .then((response) => {
        setAuth({userid, session});
        navigate('/userpage', { state: { menuElement: 'userPassword' } });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={style.forgetPasswordForm}>
        <div className={style.formGroup}>
          <label>Email</label>
          <br />
          <input
            className={style.inputBox}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <button className={style.formButton} onClick={handleEmailSubmit}>
            Send Authentification
          </button>
        </div>
        <div
          className={style.keyField}
          
        >
          <label>Authentification Key</label>
          <br />
          <input
            className={style.inputBox}
            type="text"
            value={authentificationKey}
            onChange={(e) => setAuthentificationKey(e.target.value)}
            required
          />
          <br />
          <button
            id="userPage"
            className={style.formButton}
            onClick={handleAuthentificationSubmit}
          >
            Reset Password
          </button>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
