import React from 'react';
import { Link } from 'react-router-dom';

const ChatEntry = ({ chat }) => {
  const defaultProfilePic = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png"
  const harryPotterProfilePic = "https://avatarfiles.alphacoders.com/139/139815.jpg"
  const containerStyle = {
    display: 'flex',
    paddingInline: '15px',
    paddingBlock: '10px',
    borderBottom: '1px solid lightgrey',
    transition: 'border-bottom 0.3s', 
  }

  const textDisplayStyle = {
    width: '100%',
    marginLeft: '15px',
    maxHeight: '50px',
    whiteSpace: 'nowrap', 
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
  const profilePicStyle = {
    width: '50px',
    height: '50px',
    minWidth: '50px',
    minHeight: '50px',
    borderRadius: '25px',
  };
  const contactNameStyle = {
    fontWeight: 'bold',
  }
  const timeStampStyle = {
    width: '150px',
    textAlign: 'right',
  }
  return (
    <Link to={"/chats/"+chat.userid} style={{ textDecoration: 'none', color:'black'}}>
    <div className="chatList" style={containerStyle}>
      <img style={profilePicStyle} src={chat.useravatar ? "https://oss.umiuni.com/" + chat.useravatar : chat.userid === "kQWjnegLN6awZ1p0" ? harryPotterProfilePic : defaultProfilePic}></img>
      <div style={textDisplayStyle}>
        <div style={contactNameStyle}>{chat.username ? chat.username : "_"}</div>
        <div>{chat.lastMessage}</div>
      </div>
      <div style={timeStampStyle}>{chat.lastMessageTime}</div>
    </div>
    </Link>
  );
};

export default ChatEntry;
