import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import style from "./content_sharing.module.css";
import axios from "axios";
import { IoIosClose } from 'react-icons/io';
import { AuthContext } from "../context/AuthContext";

const ContentSharingPost = () => {
    const { userid, contentid } = useParams(); // Extract userid from the URL
    const [contentList, setContentList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);  
    const { auth } = useContext(AuthContext);
    const [selfUserId, setSelfUserId] = useState(null);
    const [status, setStatus] = useState(null);

    const updateStatus = async (action, targetUserId) => {
        try {
            const data = {
                from_user_id: selfUserId,
                to_user_id: targetUserId,
            };

            const url = `https://ai-api.umiuni.com:8183/friends/${action}`;
            const result = await axios.post(url, data, {
                headers: { "Content-Type": "application/json" },
            });

            if (action === "check") {
                console.log(result.data);
                return result.data;
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchContent = async() => {
            try {
                const { data } = await axios.get(`https://python.umiuni.com:5555/umiuni_content_getting_self/${userid}/?pwd=testing`);
                setContentList(data);
                const targetIndex = data.findIndex(item => item.id == contentid);
                setCurrentIndex(targetIndex);
                const statusResponse = await updateStatus("check", selectedUserId);
                setStatus(statusResponse?.status);
            } catch (error) {
                console.error(error);
            }
        };

        fetchContent();
    }, [userid]);

   const createCards = () => {
     if (contentList.length === 0 || currentIndex === null) return null;

     const item = contentList[currentIndex];

     // Additional fields
     const additionalFields = {
	    id: item.id || null,
        userid: item.userid || null,
        created_time: item.created_time || null,
        photo_location: item.photo_location || null,
        content_name: item.content_name || null,
        page_num: item.page_num || null,
        text: item.text || null,
        book_link: item.book_link || null,
     };

        const handleStatus = async () => {
            const action = status === "following" ? "remove" : "follow";
            await updateStatus(action, additionalFields.userid);

            setStatus(status === "following" ? "none" : "following");
        };

     return (
        <div className={style.contentWrapper} key={currentIndex}>
            <div className={style.content}>
                {item.photo_location?.endsWith('.pdf') ? (
                    <a href={item.photo_location}>{item.content_name}</a>
                ) : (
                    <img src={item.photo_location} alt={item.content_name} />
                )}
            </div>
            {/* Display additional fields if they are not null */}
            <div className={style.additionalInfo}>
                {additionalFields.userid && (
                    <>
                        <p className={style.userIdCont}>User ID: {additionalFields.userid}</p>
                        <button className={style.updateStatus} onClick={handleStatus}>
                            {status === "following" ? "Unfollow" : "Follow"}
                        </button>
                       
			            <button
                          onClick={() => {
                            const shareUrl = `https://umiuni.com/content_sharing/${additionalFields.userid}/${additionalFields.id}`;
                            if (navigator.share) {
                              navigator.share({
                              title: 'Check out this content!',
                              text: `Take a look at this content shared by ${additionalFields.userid}.`,
                              url: shareUrl,
                            })
                            .then(() => console.log('Successfully shared'))
                            .catch((error) => console.error('Error sharing:', error));
                            } else {
                              // Fallback for unsupported browsers
                              console.warn('Share API not supported. Copying link to clipboard...');
                              navigator.clipboard.writeText(shareUrl).then(() => {
                                alert('Link copied to clipboard');
                              });
                            }
                          }}
                          className={style.updateStatus}
                        >
                          Share
                        </button>
                    </>
                )}
                {additionalFields.created_time && <p>Created Time: {additionalFields.created_time}</p>}
                {additionalFields.page_num && <p>Page Number: {additionalFields.page_num}</p>}
                {additionalFields.content_name && <p>Book Name: {additionalFields.content_name}</p>}
                {additionalFields.text && <p>Text: {additionalFields.text}</p>}
                {additionalFields.book_link && (
                    <div className={style.addBook}>
                        <p>Book Link:</p>
                        <a href={additionalFields.book_link}>View Book</a>
                    </div>
                )}
            </div>
        </div>
    );
   };

    return (
        <div className={style.contentCards}>
            {createCards()}
        </div>
    );
};

export default ContentSharingPost;
