import React from "react";
import "./ChatBubble.css"; // Import the CSS file for ChatBubble

export function parseTime(timestamp_string) {
  const date = new Date(timestamp_string);
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  const seconds =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * # Chat Bubble Element
 * @param {{content: string,
 * self_flag: boolean,
 * timestamp: number || undefined,
 * sender: undefined || {
 * pfp: string, name: string}}} parameters
 * @returns JSX Element
**/
export function ChatBubble({
  content,
  self_flag,
  timestamp,
  sender,
}) {
  const message_class_string = self_flag ? "received-message" : "sent-message";
  return (
    <>
      <div className="time">{parseTime(timestamp)}</div>
      {sender && (
        <div
          className="userProfile"
          style={{ display: !self_flag ? "none" : "block" }}
        >
          <img
            src={`https://oss.umiuni.com/${sender.pfp}`}
            className="avatar"
          />
          <div className="senderName">{sender.name}</div>
        </div>
      )}
      <div className={`message ${message_class_string}`}>{content}</div>
    </>
  );
}
