import React from "react";

import Header from "../header/Header";

import "./FAQ.css";

const FAQ = () => {
  return (
    <div>
      <div className="container">
        <h1>Frequent Questions</h1>
        <ul>
          <li>
            <p class="question">1. Who are UmiUni?</p>
            <p class="answer">
	    UmiUni.com is an academic/knowledge information platform established by North American university alumni, designed to serve the needs of fellow alumni. It facilitates the exchange of academic and research-related resources, allowing users to engage in activities such as buying and selling second-hand academic materials, posting listings for apartment subleases, and trading personal vehicles. Additionally, it provides access to information on local academic conferences, seminars, and job opportunities. The platform is organized into university-specific and geographically-based networks, enabling efficient communication and collaboration among users, thus simplifying academic resource sharing and transactions.
            </p>
          </li>
          <li>
            <p class="question">2. Is UmiUni a paid platform or a free platform?</p>
            <p class="answer">
              答：UmiUni is a free platform with membership upgrades on premium services.
            </p>
          </li>
          <li>
            <p class="question">3. UmiUni Registration Requirements?</p>
            <p class="answer">
	     Users must be North American alumni, and they can verify their school affiliation using an official school email with a .edu or .edu.cn domain. Additionally, the platform has extended support for Gmail users. The majority of users are current or former North American university alumni, so we encourage everyone to use the platform with confidence.
            </p>
          </li>
          <li>
            <p class="question">4. Can non-university students register?</p>
            <p class="answer">
	      Yes, we can have both university and non-university students(graduated alumni) register.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FAQ;
