import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemCard from "../item_cards/Item_card";
import style from '../../layout.module.css';
import axios from "axios";

const ItemEvents = () => {
    let schoolId;
    const storedSchool = JSON.parse(localStorage.getItem("savedSchool"));
  
    if (storedSchool === null) {
      schoolId = 1;
    }
    else {
      schoolId = storedSchool.id;
    }
  
    const [returnDataItems, setReturnDataItems] = useState({});
    const [eventCards, setEventCards] = useState(null);
    
    const navigate = useNavigate();

    let itemTypes = ["event"];
    
    useEffect(() => {
        if (Object.keys(returnDataItems).length === itemTypes.length) {
          createCards();
        }
    }, [returnDataItems]);

    useEffect(() => {
        const fetchDataItems = async () => {
            let items = {};
    
            for (let i = 0; i < itemTypes.length; i++) {
                let urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=12&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
        
            if (itemTypes[i] === "merchant_house") {
                urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=18&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
            }
    
            const config = {
                method: "get",
                url: urlValue,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {},
            };

            try {
                const response = await axios(config);
                items[itemTypes[i]] = response.data.items;
                } catch (error) {
                console.log(error);
                }
            }
          setReturnDataItems(items);
        };
        fetchDataItems();
    }, [schoolId]);

    const detailNavigation = (iid) => {
        return "/detail/" + iid;
    };

    const makeItemCards = (items) => {
        let src = "https://oss.umiuni.com/";
    
        return items.map((currentItem, i) => (
            <ItemCard
                item = {currentItem}
                imageUrl = {src + currentItem.photo}
                onClick = {() =>
                window.location.href = detailNavigation(currentItem.iid)
                }
            />
        ));
    }

    const createCards = () => {
        console.log(returnDataItems);
    
        Object.keys(returnDataItems).forEach((itemType) => {
            const items = returnDataItems[itemType];
            const itemCards = makeItemCards(items);
    
            switch(itemType) {
                case "event":
                setEventCards(itemCards);
                break;
            }
        })
    };

    const redirectSearch = (type) => {
        switch(type) {
            case "Moreevent":
            navigate("/search/event/0");
            break;
        }
    }


    return (
        <Fragment>
            <div className = {style.homepageContainer}>
                <div className = {style.categoryTitle}>
                    <div className = {style.title}>
                        Events
                    </div>
                    <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Moreevent")}>
                        More
                    </div>
                </div>
                <div className = {style.cardsContainer}>
                    {eventCards}
                </div>
            </div>  
        </Fragment>
    );
}

export default ItemEvents;