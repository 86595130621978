import axios from 'axios';
import style from './header_dropdown.module.css';
import { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import Logout from '../logout/Logout';

const HeaderDropdown = () => {

    const {auth} = useContext(AuthContext);
    const [isActive, setIsActive] = useState(false);
    const [userData, setUserData] = useState(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();


    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}api/user/get`, {
            params: {
                "userid": auth.userid
            }
        })
        .then((response) => {
            setUserData(response.data);
        })
        .catch((error) => {
            console.log(error);
        })

        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    const retAvatar = () => {
        if(userData != null) {
          return userData.avatar;
        }
    }

    const retUsername = () => {
        if(userData != null) {
            return userData.username;
        }
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsActive(false);
        }
    };

    const toggleDropdown = () => {
        setIsActive(!isActive);
    }
    const handleProfile = () => {
        navigate('/userpage', { state: { menuElement: 'userProfile' } });
        toggleDropdown();
    }

    const handlePassword = () => {
        navigate('/userpage', { state: { menuElement: 'userPassword' } });
        toggleDropdown();
    }

    const handleManage = () => {
        navigate('/userpage', { state: { menuElement: 'userManage' } });
    }

    // const handlePost = () => {
    //     navigate('/post', { state: {menuElement: 'post'}});
    // }
    const handleContacts = () => {
        navigate('/userpage', { state: {menuElement: 'userContacts' } });
    }

    const handlePost = () => {
        navigate('/post', { state: {menuElement: 'post'}});
    }

    return (
        <div className = {style.navLinks}>
        <div onClick = {toggleDropdown} ref = {dropdownRef} className = {style.dropdownField}>
            <img src={`https://oss.umiuni.com/${retAvatar()}`} />
            &nbsp;
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{retUsername()}</div>
            <ul className={`${isActive ? style.dropdown_active : style.dropdown}`}>
                <li className = {style.list} onClick = {handleProfile}>Profile</li>
                {/* <li className = {style.list} onClick = {handlePassword}>Password</li> */}
                {!userData?.email?.endsWith("@gmail.com")? <li className = {style.list} onClick = {handlePassword}>Password</li>: <></>}

                <li className = {style.list} onClick = {handleManage}>Manage</li>
                <li className = {style.list} onClick = {handleContacts}>Contacts</li>
                <Logout className = {style.list} />
            </ul>
        </div>
        {/*<a class = "button" href = "/post" className = {style.postButton}>Post</a>*/}
        </div>
    );
}

export default HeaderDropdown;
