import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemCard from "../item_cards/Item_card";
import style from '../../layout.module.css';
import axios from "axios";
import { IoMdMenu } from 'react-icons/io';

const ItemUsedItems = () => {
    let schoolId;
    const storedSchool = JSON.parse(localStorage.getItem("savedSchool"));
  
    if (storedSchool === null) {
      schoolId = 1;
    }
    else {
      schoolId = storedSchool.id;
    }
  
    const [returnDataItems, setReturnDataItems] = useState({});
    const [usedCards, setUsedCards] = useState(null);
  
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    
    const navigate = useNavigate();

    let itemTypes = ["used"];

    const usedItemOptions = [
        { value: "books", label: "Books" },
        { value: "Materials", label: "Materials" },
        { value: "Electronics", label: "Electronics" },
        { value: "Instruments", label: "Instruments" },
        { value: "Accessories", label: "Accessories" },
        { value: "Clothing", label: "Clothing" },
        { value: "Sporting", label: "Sporting" },
        { value: "Misc", label: "Misc" }
    ];
    
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkMobile();
        window.addEventListener("resize", checkMobile);
        return () => {
          window.removeEventListener("resize", checkMobile);
        };
    }, [])
    
    useEffect(() => {
        if (Object.keys(returnDataItems).length === itemTypes.length) {
          createCards();
        }
    }, [returnDataItems]);

    useEffect(() => {
        const fetchDataItems = async () => {
            let items = {};
    
            for (let i = 0; i < itemTypes.length; i++) {
                let urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=12&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
        
            if (itemTypes[i] === "merchant_house") {
                urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=18&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
            }
    
            const config = {
                method: "get",
                url: urlValue,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {},
            };

            try {
                const response = await axios(config);
                items[itemTypes[i]] = response.data.items;
                } catch (error) {
                console.log(error);
                }
            }
          setReturnDataItems(items);
        };
        fetchDataItems();
    }, [schoolId]);

    const detailNavigation = (iid) => {
        return "/detail/" + iid;
    };

    const makeItemCards = (items) => {
        let src = "https://oss.umiuni.com/";
    
        return items.map((currentItem, i) => (
            <ItemCard
                item = {currentItem}
                imageUrl = {src + currentItem.photo}
                onClick = {() =>
                window.location.href = detailNavigation(currentItem.iid)
                }
            />
        ));
    }

    const createCards = () => {
        console.log(returnDataItems);
    
        Object.keys(returnDataItems).forEach((itemType) => {
            const items = returnDataItems[itemType];
            const itemCards = makeItemCards(items);
    
            switch(itemType) {
                case "used":
                setUsedCards(itemCards);
                break;
            }
        })
    };

    const redirectSearch = (type) => {
        switch(type) {
            case "books": 
                navigate("/search/used/书/0");
                break;
            case "Materials": 
                navigate("/search/used/学习用具/0");
                break;
            case "Electronics":
                navigate("/search/used/电子数码/0");
                break;
            case "Instruments":
                navigate("/search/used/乐器/0");
                break;
            case "Accessories":
                navigate("/search/used/包包及配饰/0");
                break;
            case "Clothing":
                navigate("/search/used/衣服鞋帽/0");
                break;
            case "Sporting":
                navigate("/search/used/运动器材/0");
                break;
            case "Misc":
                navigate("/search/used/其他/0");
                break;
            case "Moreused":
                navigate("search/used/0");
                break;
        }
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    
    const handleDropdownOptionClick = (optionValue) => {
        setSelectedOption(optionValue);
        setIsOpen(false);
        redirectSearch(optionValue);
    };
    
    const renderDropdownOptions = (options) => {
      return (
        <div className={style.dropdown}>
          <button onClick={toggleDropdown}><IoMdMenu /></button>
          <div className={`${style.dropdownContent} ${isOpen ? style.show : null}`}>
            {options.map(option => (
              <div key={option.value} onClick={() => handleDropdownOptionClick(option.value)}>
                {option.label}
              </div>
            ))}
          </div>
        </div>
      );
    };

    return (
        <Fragment>
            <div className = {style.homepageContainer}>
                <div className = {style.categoryTitle}>
                    <div className = {style.title}>
                        Used Items
                    </div>
                {isMobile ? (
                <>
                    {renderDropdownOptions(usedItemOptions)}
                </>
                ) : (
                <>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("books")}>Books</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Materials")}>Materials</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Electronics")}>Electronics</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Instruments")}>Instruments</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Accessories")}>Accessories</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Clothing")}>Clothing</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Sporting")}>Sporting</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Misc")}>Misc</div>
                </>
                )}
                <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Moreused")}>
                    More
                </div>
            </div>
            <div className = {style.cardsContainer}>
                {usedCards}
            </div>
            </div>  
        </Fragment>
    );
}

export default ItemUsedItems;