import React from "react";
import { Link } from "react-router-dom";
import "./SuccessPage.css";

const SuccessPage = () => {
  return (
    <div className="success-page-wrapper">
      {/* Include your header here if applicable */}
      <div className="success-page-container">
        <div className="card">
          <div
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#F8FAF5",
              margin: "0 auto",
            }}
          >
            <i className="checkmark">✓</i>
          </div>
          <h1 className="successPage_h1">Success</h1>
          <p className="successPage_p">
            We received your payment and appreciate your business!
            <br />
            If you have any questions, please email: admin@umiuni.com
          </p>
          <Link to="/" className="home-button-link">
            <button className="home-btn">Go Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;



