import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import ChatBubble from "./ChatBubble";
import { AuthContext } from "../context/AuthContext";
import "./sendChat.css"; // Import your styles

const SendChat = () => {
  const [chats, setChats] = useState([]);
  const [textBoxValue, setTextBoxValue] = useState("");
  const { auth } = useContext(AuthContext);
  const { to_userid } = useParams();

  const parsingDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    const day =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const hours =
      date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    const seconds =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const chatInput = {
    width: "100%",
    display: "flex",
  };

  const chatInputBox = {
    maxWidth: "100%",
    width: "calc(100vw - 80px)",
  };

  const sendButton = {
    padding: "10px",
    backgroundColor: "rgb(51,117,198)",
    width: "80px",
    color: "white",
    textAlign: "center",
    borderRadius: "6px",
    cursor: "pointer",
  };

  const chatArea = {
    height: "80vh",
    overflowY: "scroll",
    paddingTop: '4rem'
  };

  const sendMessage = (messageContent) => {
    const data = JSON.stringify({
      userid: auth.userid,
      session: auth.session,
      to_userid: to_userid,
      content: messageContent,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}api/message/ai_chat`,
      headers: {
        //  'Content-Type': 'application/json',
        "Content-Type": "application/json; charset=utf-8",
      },
      data: data,
    };

    // return axios.request(config);
    return axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchMessages = () => {
    const data = JSON.stringify({
      userid: auth.userid,
      session: auth.session,
      to_userid: to_userid,
    });
    //console.log(data,auth);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}api/message/get`,
      headers: {
        // 'Content-Type': 'application/json',
        "Content-Type": "application/json; charset=utf-8",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        //console.log(response.data); // response.data is an object
        const responseChat = response.data.reverse(); // const responseChat = response.data.reverse();
        const parsedChat = responseChat.map((chat) => ({
          sendOut: chat.from_userid === auth.userid ? "true" : "false",
          send_timestamp: parsingDate(chat.send_timestamp),
          content: chat.content,
        }));
        setChats(parsedChat);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchMessages();
  }, [to_userid]);

  const handleTextBoxChange = (event) => {
    setTextBoxValue(event.target.value);
  };

  const handleSendClick = () => {
    sendMessage(textBoxValue)
      .then(() => {
        fetchMessages();
        setTextBoxValue("");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div style={chatArea} className="chat-container">
        {chats.map((chat, index) => (
          <div
            key={index}
            className={`message ${
              chat.sendOut === "true" ? "received-message" : "sent-message"
            }`}
          >
            {chat.content}
          </div>
        ))}
      </div>
      <div className="text-box-container" style={chatInput}>
        <input
          type="text"
          value={textBoxValue}
          style={chatInputBox}
          onChange={handleTextBoxChange}
          placeholder="Type something..."
        />
        <div style={sendButton} onClick={handleSendClick}>
          Send
        </div>
      </div>
    </div>
  );
};

export default SendChat;
