import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemCard from "../item_cards/Item_card";
import style from '../../layout.module.css';
import axios from "axios";
import { IoMdMenu } from 'react-icons/io';

const ItemRentals = () => {
    let schoolId;
    const storedSchool = JSON.parse(localStorage.getItem("savedSchool"));
  
    if (storedSchool === null) {
      schoolId = 1;
    }
    else {
      schoolId = storedSchool.id;
    }
  
    const [returnDataItems, setReturnDataItems] = useState({});
    const [houseCards, setHouseCards] = useState(null);
  
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    
    const navigate = useNavigate();

    let itemTypes = ["house"];

    const rentalOptions = [
        { value: "Longterm", label: "Long term" },
        { value: "Shortterm", label: "Short term" },
        { value: "Oncampus", label: "On campus" },
        { value: "Offcampus", label: "Off campus" },
        { value: "Roommates", label: "Roommates" }
    ];
    
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkMobile();
        window.addEventListener("resize", checkMobile);
        return () => {
          window.removeEventListener("resize", checkMobile);
        };
    }, [])
    
    useEffect(() => {
        if (Object.keys(returnDataItems).length === itemTypes.length) {
          createCards();
        }
    }, [returnDataItems]);

    useEffect(() => {
        const fetchDataItems = async () => {
            let items = {};
    
            for (let i = 0; i < itemTypes.length; i++) {
                let urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=12&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
        
            if (itemTypes[i] === "merchant_house") {
                urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=18&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
            }
    
            const config = {
                method: "get",
                url: urlValue,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {},
            };

            try {
                const response = await axios(config);
                items[itemTypes[i]] = response.data.items;
                } catch (error) {
                console.log(error);
                }
            }
          setReturnDataItems(items);
        };
        fetchDataItems();
    }, [schoolId]);

    const detailNavigation = (iid) => {
        return "/detail/" + iid;
    };

    const makeItemCards = (items) => {
        let src = "https://oss.umiuni.com/";
    
        return items.map((currentItem, i) => (
            <ItemCard
                item = {currentItem}
                imageUrl = {src + currentItem.photo}
                onClick = {() =>
                window.location.href = detailNavigation(currentItem.iid)
                }
            />
        ));
    }

    const createCards = () => {
        console.log(returnDataItems);
    
        Object.keys(returnDataItems).forEach((itemType) => {
            const items = returnDataItems[itemType];
            const itemCards = makeItemCards(items);
    
            switch(itemType) {
                case "house":
                setHouseCards(itemCards);
                break;
            }
        })
    };

    const redirectSearch = (type) => {
        switch(type) {
            case "Longterm":
                navigate("/search/house/长租/0");
                break;
            case "Shortterm":
                navigate("/search/house/短租/0");
                break;
            case "Oncampus":
                navigate("/search/house/校内/0");
                break;
            case "Offcampus":
                navigate("/search/house/校外/0");
                break;
            case "Roommates":
                navigate("/search/house/找室友/0");
                break;
            case "Morerent":
                navigate("/search/house/0");
                break;
        }
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    
    const handleDropdownOptionClick = (optionValue) => {
        setSelectedOption(optionValue);
        setIsOpen(false);
        redirectSearch(optionValue);
    };
    
    const renderDropdownOptions = (options) => {
      return (
        <div className={style.dropdown}>
          <button onClick={toggleDropdown}><IoMdMenu /></button>
          <div className={`${style.dropdownContent} ${isOpen ? style.show : null}`}>
            {options.map(option => (
              <div key={option.value} onClick={() => handleDropdownOptionClick(option.value)}>
                {option.label}
              </div>
            ))}
          </div>
        </div>
      );
    };

    return (
        <Fragment>
            <div className = {style.homepageContainer}>
                <div className = {style.categoryTitle}>
                    <div className = {style.title}>
                        Rentals
                    </div>
                {isMobile ? (
                <>
                    {renderDropdownOptions(rentalOptions)}
                </>
                ) : (
                <>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Longterm")}>Long term</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Shortterm")}>Short term</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Oncampus")}>On campus</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Offcampus")}>Off campus</div>
                    <div className={style.hyperLinks} onClick={() => redirectSearch("Roommates")}>Roommates</div>
                </>
                )}
                <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Morerent")}>
                    More
                </div>
            </div>
            <div className = {style.cardsContainer}>
                {houseCards}
            </div>
            </div>  
        </Fragment>
    );
}

export default ItemRentals;