import React from "react";
import style from "./header_searchbar.module.css";
import { useNavigate } from "react-router-dom";


const HeaderChatRoomButton = () => {

	const navigate = useNavigate();
	const handleClick = () => {
        
            let url = "/chats";
            navigate(url);
        }
 return (	
	<div>
	 <button className = {style.searchButton} onClick = {handleClick}>Chats</button>
	</div>
);

}


export default HeaderChatRoomButton;
