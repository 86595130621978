import React from "react";
import style from './header_register.module.css';
import { NavLink } from "react-router-dom";

const HeaderRegister = () => {
    return (
        <nav className={style.navLinks}>           
            <NavLink to="/login" className = {style.links}>Login</NavLink>
            <NavLink to="/verifyemail" className = {style.links}>Register</NavLink>
        </nav>
    );
};

export default HeaderRegister;
