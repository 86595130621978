import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();


  const handleLogout = () => {
    {logout()}
    navigate("/");
  }

  return (
    <li onClick={handleLogout}>Logout</li>
  )
}

export default Logout;