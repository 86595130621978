import axios from 'axios'
import { React, useRef, useState, useEffect, useContext} from 'react';

const GetSchoolList = async() => {
  var axios = require('axios');

  var config = {
	  method: 'get',
	  url: `${process.env.REACT_APP_API_URL}api/school/list`,
	  headers: { }
  };

  return await axios(config)
  .then(function (response) {
	  //console.log(JSON.stringify(response.data));
	return response.data;
  })
  .catch(function (error) {
	  //console.log(error);
  });
}

export default GetSchoolList;

