import React from "react";
import style from './user_contacts.module.css';
import { AuthContext } from "../context/AuthContext";
import { useEffect, useContext, useState } from "react";
import axios from "axios";

const UserContacts = () => {

    const [contacts, setContacts] = useState(null);
    const [indivContacts, setIndivContacts] = useState(null);
    const [contactCards, setContactCards] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [newContact, setNewContact] = useState("");

    const axios = require('axios');
    const { auth } = useContext(AuthContext);

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}api/user/get`, {
            params: {
              "userid": auth.userid
            }
        })
        .then((response) => {
            setContacts(response.data.contact);
        })
        .catch((error) => {
            console.log(error);
        })

    }, []);

    useEffect(() => {
        if(contacts) {
            processContacts();
        }
    }, [contacts]);

    useEffect(() => {
        if(indivContacts) {
            makeContacts();
        }
    }, [indivContacts]);

    const processContacts = () => {

        let separateContacts = [];
        let currentContact = "";

        for(let i = 0;i < contacts.length; i++) {
            if(contacts[i] != '%') {
                currentContact += contacts[i];
            } else if(contacts[i] === '%') {
                separateContacts.push(currentContact);
                currentContact = "";
            }
        }
        separateContacts.push(currentContact);

        setIndivContacts(separateContacts);
    }
    
    const makeContacts = () => {

        let content = [];

        for(let i = 0;i < indivContacts.length; i++) {
            content.push(
                <>
                    <div
                        key = {i}
                        onClick = {(event) => handleContactChange(i, event)}
                        className = {style.contactCards}
                    >
                        {indivContacts[i]}
                    </div>
                </>
            );
        }

        setContactCards(content);
    }

    const handleContactChange = (index) => {
        const updatedContacts = [...indivContacts];
        updatedContacts.splice(index, 1);
        setIndivContacts(updatedContacts);
    }

    const condenseContacts = () => {
        let res = "";
        
        for(let i = 0;i < indivContacts.length; i++) {
            if(i > 0) {
                res += "%";
            }
            res += indivContacts[i];
        }

        return res;
    }

    const handleUpdate = () => {
        let contactString = condenseContacts();
        fetch(`${process.env.REACT_APP_API_URL}api/user/set`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userid: auth.userid,
                session: auth.session,
                contact: contactString
            })
        })
        .then((response) => { 
        })
        .catch((error) => {
            console.log(error);
        })

    }

    const handleAddContact = (index) => {
        togglePopup();
    }

    const togglePopup = () => {
        if(!isOpen) {
            setNewContact("");
        }
        setIsOpen(!isOpen);
    }

    const handleInputChange = (event) => {
        setNewContact(event.target.value);
    }

    const addContact = () => {
        if(newContact === "") {
            return;
        }
        let updatedContacts = [...indivContacts];
        updatedContacts.push(newContact);
        setIndivContacts(updatedContacts);
        togglePopup();
    }

    return (
        <div className = {style.contactPage}>
            {isOpen && (
                <div className = {style.popup}>
                    <div className  ={style.popupContent}>
                        <h3>Add Contact</h3>
                        <input 
                            type = "text"
                            value = {newContact}
                            onChange = {handleInputChange}
                            className = {style.inputField}
                        />
                        <div className = {style.buttonsContainer}>
                            <button onClick = {togglePopup} className = {style.cancelButton}>Cancel</button>
                            <button onClick = {addContact} className = {style.confirmButton}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
            <div className = {style.contactTop}>
                <div className = {style.title}>
                    Contacts
                </div>
            </div>
            <div className = {style.contactBottom}>
                {contactCards}
            </div>
            <button className = {style.addButton} onClick = {handleAddContact}>Add</button>
            <button className = {style.updateButton} onClick = {handleUpdate}>Update</button>
        </div>
    );
}

export default UserContacts;
