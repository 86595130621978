import axios from 'axios';
import { useRef, useState, useEffect, useContext } from 'react';
import style from './user_change_password.module.css';
import { AuthContext } from "../context/AuthContext";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const UserChangePassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [displayWarning, setDisplayWarning] = useState(false);

    const [warningMessage, setWarningMessage] = useState("Passwords do not match");
    const passwordRegex = /^([0-9a-zA-Z_\\!@#$%^&*-]){6,20}$/;


    const { auth } = useContext(AuthContext);

/*    useEffect(() => {

    })*/

    const checkWarning = () => {
        if(displayWarning) {
            setDisplayWarning(false);
            console.log(displayWarning);
        }
    }

    const handleNewPassword = (event) => { 
        removeWarning();
        setNewPassword(event.target.value);
    }

    const handleConfirmPassword = (event) => {
        removeWarning();
        setConfirmPassword(event.target.value);
    }

    const removeWarning = () => {
        setDisplayWarning(false);
    }

    const handleUpdate = () => {
        
        if(newPassword != "" && confirmPassword != "" && 
            passwordRegex.test(newPassword) && passwordRegex.test(confirmPassword) && 
            newPassword === confirmPassword) {

            fetch(`${process.env.REACT_APP_API_URL}api/user/set`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userid: auth.userid,
	                session: auth.session,
                    password: newPassword
                })
            })

            .then(response => response.json())
            .then((response) => {
                //console.log(JSON.stringify(response));
                const changeMessage = () => {
                    setWarningMessage("Updated successfully");
                    setDisplayWarning(true);
                };
                changeMessage(); 
            })
            .catch((error) => {
                //console.log(error);
            })
        } else {
            setWarningMessage("Passwords do not match");
            setDisplayWarning(true);
            //console.log("password Error");
            return;
        }
    }

    return (
        <>
            <div className = {style.userPasswordPage}>
                <div className = {style.profileTop}>
                    <div className = {style.title}>Change Password</div>
                </div>
                <div className = {style.margin}>
                    <p className={style.subtitle}>New Password</p>
                    <input
                        type = "password"
                        placeholder = "6-20 digits long"
                        value = {newPassword}
                        onChange = {handleNewPassword}
                    />
                    <p> </p>
                    <p className={style.subtitle}>
                        Confirm Password</p>
                    <input
                        type = "password"
                        placeholder = "6-20 digits long"
                        value = {confirmPassword}
                        onChange = {handleConfirmPassword}
                    />
                </div>
                <p></p>
                <div className = {style.subtitle} style = {{visibility: displayWarning ? 'visible' : 'hidden'}}>{warningMessage}</div>
                <button className = {style.updateButton} onClick = {handleUpdate}>Update</button>
            </div>
        </>
    );
}

export default UserChangePassword;
