import axios from "axios";
import { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Header from "../header/Header";
import classes from "./login.module.css";
import style from "./login.module.css";
import PasswordReset from "../password_reset/Password_reset";

const Login = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  var axios = require("axios");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  // superchaoran&Jeffery:
  // Dec 30th, 2023
  // google login callback to UmiUni login page
  // then UmiUni take uesrid&session to store in setAuth, application context
  // if page five userid, session parameter.
  // e.g. https://localhost:3000/login/hashed(6812)/vQLEwP2QVfsmTOXKh3KJFoZvo
  const { userid, session } = useParams();
  useEffect(() => {
    if (userid && session) {
      const school_abbr = 'UmiUni';
      setAuth({ userid, session, school_abbr });
      console.log("Handle successful response, for google user login");
      console.log('userid:', userid, 'session:', session, 'school_abbr:', school_abbr);
      setUser("");
      setPwd("");
    }
  }, [userid, session]); // this means this userEffect function depends on [userid, session]

  /*Ensure State is Persisted Before Navigation:
You can add a useEffect to listen for changes in the auth state and then navigate, ensuring the state is persisted before redirecting.
  In this approach:
The first useEffect sets the auth state.
The second useEffect listens for changes in auth and only navigates when the state is properly set.
 */
  useEffect(() => {
    if (auth.userid && auth.session) {
      navigate("/");
    }
  }, [auth, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    var data = JSON.stringify({
      username: user,
      password: pwd,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    
     axios(config)
     .then(function (response) {
       if (response.data.error) {
         // Alert the user with the error message
         const errorMessage = response.data.error;
         console.log(errorMessage); // Or use a UI alert mechanism
         alert(errorMessage);
       } else {
         // Handle successful response
         // console.log(JSON.stringify(response.data));
         const userid = response.data.userid;
         const session = response.data.session;
         const school_abbr = response.data.school_abbr;
         console.log("Handle successful response, for edu user login");
         console.log('userid:', userid, 'session:', session, 'school_abbr:', school_abbr);
         setAuth({ userid, session, school_abbr });
         setUser("");
         setPwd("");
         navigate("/");
       }
     })
     .catch(function (error) {
       // Handle Axios or network errors
       console.error("Error fetching data:", error);
       // Optionally, display an error message to the user
       alert(error);
     });
   
  };

   const handleLogin = async () => {     
		try {       
			const response = await axios.get('https://umiuni.com:5002/auth/google');       
			// Redirect the user to the authentication URL received from the backend       
			window.location.href = response.data.redirectUrl;    
		} catch (error) {     
			console.error('Error occurred while initiating Google OAuth:', error);   
		} 
	};
  return (
    <>
      <div className={style.outer}>
        <div className={style.loginFrame}>
          {success ? (
            <section>
              <h1>You are logged in!</h1>
              <br />
              <p>
                <a href="#">Go to Home</a>
              </p>
            </section>
          ) : (
            <div id="login_content">
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <h1>Sign In</h1>
              <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="on"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                />

                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                />
                <br />
                <a href="/passwordreset">Forgot password?</a>
                <a href="https://umiuni.com:5002/auth/google" className={style.googleButton}>
                  <span className={style.googleText}>Google Login</span>
                  <img 
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/800px-Google_%22G%22_logo.svg.png" 
                    alt="Google logo" 
                    className={style.googleLogo}
                  />
                </a>
                <button className={style.signInButton}>Sign In</button>
              </form>

              <p>
                Need an Account?
                <br />
                <span className="line">
                  {/*put router link here*/}
                  <a href="/verifyemail">Register</a>
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
